.main {
  width: 100%;
  background: var(--global-light-grey, #f3f8f8);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  padding: 0 35px;
  width: 95%;
  background-color: var(--global-light-grey, #f3f8f8);
  max-width: 1600px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content h1 {
  color: var(--Global-Grey, #243030);
  font-family: Lexend;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: -0.087px;
}

.border {
  border: 1px solid var(--global-outline-grey, #dae8e7);

  margin-top: 10px;
  margin-bottom: 30px;
}

@media (max-width: 425px) {
  .content {
    padding: 0 16px;
  }
  .learner_header h2 {
    font-size: 17px;
  }
  .learner_header {
    flex-direction: column;
    gap: 16px;
  }
  .search {
    width: 90%;
  }
  .learner_header_buttons {
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }

  .coach_roaster {
    padding: 20px;
  }

  .learner_table thead th {
    font-size: 10px;
  }

  .modal_view {
    width: 80%;
  }
}

.container {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  width: 100%;
  background-color: #fff;
  flex-direction: column;
  align-items: start;
  padding: 20px;
  gap: 40px;
}

.nav_btn1 {
  border: 1px solid #bbbfbf;
  padding: 0 15px;
  border-radius: 5px;
  width: fit-content;
  display: flex;
  gap: 8px;
  align-items: center;
}

.nav_btn2 {
  border: 1px solid #00706c;
  padding: 0 15px;
  border-radius: 5px;
  width: fit-content;
  display: flex;
  gap: 8px;
  align-items: center;
  background: none;
  outline: none;
  height: 43px;
  color: var(--Global-Grey, #00706c);
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  text-wrap: nowrap;
  text-decoration: none;
}

.nav_btn2:hover {
  background-color: #2aa87e;
  color: #fff;
}
.navbar_container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.navbar {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  height: 60px;
  justify-content: space-between;
}
.nav_right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.header {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.border2 {
  border: 1px solid var(--global-outline-grey, #dae8e7);
}

.widget_container {
  display: grid;
  width: fit-content;
  gap: 80px;
  padding: 16px;
  grid-template-columns: 210px;

  @media screen and (min-width: 500px) {
    grid-template-columns: repeat(2, 210px);
  }

  @media screen and (min-width: 700px) {
    grid-template-columns: repeat(3, 230px);
  }

  @media screen and (min-width: 1200px) {
    grid-template-columns: repeat(4, 210px);
    margin: 0;
    justify-content: start;
  }
}

.summary_container {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
}
.summary_left {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.summary_right {
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.summary_info_container {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
}
.summary_info {
  display: flex;
  flex-direction: column;
  align-items: start;
}
.summary_info h3 {
  color: #243030;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.summary_info ol {
  margin-top: -10px;
  margin-left: -20px;
}
.summary_info li {
  color: #5b706f;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 30px;
}
.chart {
  width: 90%;
  height: 100%;
}
.summary_left_nav {
  display: flex;
  gap: 20px;
  align-items: center;
  width: 100%;
  justify-content: center;
}
.summary_left_nav_btn_self {
  background-color: #fff;
  border: 2px solid #02747d;
  color: #243030;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  padding: 4px 15px;
  border-radius: 5px;
  cursor: pointer;
}
.summary_left_nav_btn_self:not(:disabled):hover {
  background-color: #02747d;
  color: #ffff;
}

/* Disabled button styles */
.summary_left_nav_btn_self:disabled {
  border: 2px solid #9daaa9;
  color: #9daaa9;
}

.summary_left_nav_btn_team {
  background-color: #fff;
  border: 2px solid #1290fe;
  color: #243030;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  padding: 4px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.summary_left_nav_btn_team:not(:disabled):hover {
  background-color: #1290fe;
  color: #ffff;
}

/* Disabled button styles */
.summary_left_nav_btn_team:disabled {
  border: 2px solid #9daaa9;
  color: #9daaa9;
}

.header_container {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
}

.header_container h1 {
  color: var(--Global-Grey, #000);
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
}
