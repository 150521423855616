.main h6 {
  font-family: Lexend;
  font-size: 20px;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: -0.0031em;
  text-align: left;
  margin-bottom: 16px;
  margin-top: 26px;
}

.main p {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 300;
  line-height: 28px;
}
.border {
  border: 1px solid var(--global-outline-grey, #dae8e7);

  margin-top: 10px;
  margin-bottom: 30px;
}

.summary {
  border: 1px solid var(--global-outline-grey, #dae8e7);
  padding: 12px 16px 12px 16px;
  border-radius: 8px;
  display: flex;

  justify-content: space-between;
  align-items: center;
}

.summary h4 {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  text-align: right;
  margin: 0;
}

.summary p {
  font-family: Lexend;
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;

  margin: 0;
  text-align: right;
}

.summary h5 {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;

  margin: 0;
}

.summary_section {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.questions_section {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 10px;
  align-items: flex-start;
}

.questions_section img {
  width: 24px;
  height: 24px;
  margin-top: 3px;
}

.questions_section h6 {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  text-align: right;
  margin: 0;
}

.questions_section p {
  margin: 0;
}
.objection_section {
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: flex-start;
  background: #f3f8f8;
  width: 500px;
  padding: 8px 12px 8px 12px;
  gap: 8px;
  border-radius: 8px;
}

.objection_section p {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 300;
  line-height: 28px;
  text-align: left;
}

.objection_section span {
  width: 50px;
  height: 25px;
  background: #f3f8f8;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ff5b35;
  font-family: Lexend;
  font-size: 10px;
  margin-top: 20px;
}

.objection_list {
  display: flex;
  margin-left: 90px;
}

.summary_ratio {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}

.summary_ratio svg {
  color: #8ca19f;
}

.summary_right h4 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  text-align: right;
}

.summary_right h4 svg {
  color: #2aa87e;
  width: 18px;
  height: 18px;
}

.warning_icon {
  color: #ff5b35 !important;
}
