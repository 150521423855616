.main {
  display: flex;
  flex-direction: row;
  background: var(--global-light-grey, #f3f8f8);
}

.content {
  height: 100%;
  min-height: 100vh;
  padding: 0 35px;
  width: 98%;
}

.content h1 {
  color: var(--Global-Grey, #243030);

  /* TierOneAI/H4 */
  font-family: Lexend;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%; /* 35px */
  letter-spacing: -0.087px;
}

.content p {
  color: var(--global-body-grey, #5b706f);
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
}
.border {
  border: 1px solid var(--global-outline-grey, #dae8e7);

  margin-top: 10px;
  margin-bottom: 30px;
}

.learner_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.learner_header h2 {
  color: var(--Global-Dark-Grey, #0a1214);
  font-family: Lexend;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
  letter-spacing: -0.075px;
}

.learner_body {
  display: flex;
  flex-direction: row;
  gap: 50px;
  background: #ffffff;
  min-height: 80vh;
  border: 1px solid #dae8e7;
  border-radius: 8px;
  padding: 36px;
}

.questionList_scroll {
  overflow-y: auto;
  max-height: 65vh;
  padding-bottom: 70px;
}

.chatBox {
  width: 25%;
}

.learner_body_left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fafdfd;
  height: 70vh;
  gap: 16px;
  border-radius: 8px 0px 0px 0px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
  border: 1px solid #dae8e7;
  border-radius: 8px;
}

.learner_body_left h4 {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 300;
  line-height: 28px;
  text-align: center;
}
.learner_body_left svg {
  height: 80px;
  width: 80px;
  color: #5b706f;
  align-items: center;
}

.chatBox p {
  font-family: Lexend;
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  text-align: left;
  width: 100%;
}

.learner_body_right_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.learner_body_right_header h4 {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  margin: 0;
}

.learner_body_right_header svg {
  height: 20px;
  width: 20px;
  color: #4c12a1;
}

.learner_body_right h3 {
  font-family: Lexend;
  font-size: 24px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: -0.0031em;
  text-align: left;
  color: #40806a;
}

.answer_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: Hug (220px) px;
  height: Hug (52px) px;
  padding: 13px 24px 13px 24px;
  gap: 8px;
  border-radius: 4px;
  border: none;
  opacity: 0px;
  background: #2aa87e;
  color: #ffffff;
  cursor: pointer;
}

.answer_button svg {
  height: 20px;
  width: 20px;
  color: #ffffff;
}

.learner_body_right {
  position: relative;
  width: 76%;
  border-left: 1px solid #dae8e7;
  padding-left: 30px;
}

.question_message p {
  margin-left: 20px;
}

.learner_body_right > svg {
  position: absolute;
  top: 41%;
  left: -19px;
}

.learner_body_right_footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.learner_body_right_footer h6 {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  margin: 0;
}

.reply {
  color: #2aa87e !important;
  border: none !important;
}

.disableReplyButton {
  border: none !important;
  background: none;
}

.questionList {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 74vh;
}

.questionList button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: Lexend;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  text-align: left;
  border: 1px solid #2aa87e;
  color: #2aa87e;
  border-radius: 4px;
  background-color: transparent;
  width: 100%;
  padding: 8px 12px;
  cursor: pointer;
}
.questionList button svg {
  height: 16px;
  width: 16px;
  color: #2aa87e;
}

.questionList h3 {
  margin: 0;
  font-family: Lexend;
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  text-align: left;
  color: #5b706f;
  margin-bottom: 8px;
}

.questionList ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.questionList ul li {
  font-family: Lexend;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  text-align: left;
  color: #5b706f;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.questionList ul li:hover {
  cursor: pointer;
  background-color: #f3f8f8;
}

.questionList ul li {
  position: relative;
}

.questionList ul li .dropDown {
  position: absolute;
  right: 0;
  top: 27px;
  z-index: 1;
  background: #fff;
}

.dropDown :nth-child(1) {
  color: #e03838;
}
.lottie_section p {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
}

.lottie_section p svg {
  height: 20px;
  width: 20px;
  color: #2aa87e;
}

.lottie_section p:nth-child(2),
.lottie_section p:nth-child(3) {
  padding-left: 32px;
}

.dropDown {
  width: Fixed (160px) px;
  height: Hug (115px) px;
  padding: 8px 12px 8px 12px;
  gap: 0px;
  border-radius: 4px;
  border: 1px solid #dae8e7;
  cursor: pointer;
}

.dropDown p {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  font-family: Lexend;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  text-align: left;
  color: #5b706f;
  padding: 2px 4px;
}

.dropDown svg {
  height: 16px;
  width: 16px;
}

.dropDown p:hover {
  background: #f3f8f8;
}

.dropDown p:nth-child(3) {
  color: #e03838;
}

.modal_title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 90%;
  margin: 0 auto;
}

.modal_title h5 {
  color: var(--TierOneAI-Grey, #243030);
  text-align: center;
  font-family: Lexend;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
  letter-spacing: -0.075px;
  margin: 0;
}

.modal_title p {
  color: var(--TierOneAI-Body-Grey, #5b706f);
  text-align: center;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
  margin: 0;
}

.modal_buttons .remove {
  display: flex;
  height: 48px;
  padding: 8px 36px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  background: var(--TierOneAI-Error-Red, #e03838);
  color: var(--Global-White, #fff);
  cursor: pointer;
  border: none;
}

.modal_buttons .cancel {
  display: flex;
  height: 48px;
  padding: 8px 36px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  color: #5b706f;
  font-family: Lexend;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  border: none;
  background: #f3f8f8;

  cursor: pointer;
}

.modal_buttons {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-top: 30px;
}

.hideSectionWidth {
  width: 96%;
}

.citations p {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.citations p svg {
  height: 18px;
  width: 18px;
  color: #2aa87e;
}

.citations p:nth-child(3) svg {
  color: #ed7e00;
}

.citations p:nth-child(4) {
  color: #2aa87e;
  font-weight: 500;
}

.share_modal_title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.share_modal_title h5 {
  font-family: Lexend;
  font-size: 28px;
  font-weight: 300;
  line-height: 35px;
  letter-spacing: -0.0031em;
  text-align: left;
  color: #243030;
  margin: 0;
}

.share_modal_title svg {
  height: 24px;
  width: 24px;
  color: #243030;

  cursor: pointer;
}

.share_modal p {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 300;
  line-height: 28px;
  text-align: left;
  color: #5b706f;
}

.share_modal .modal_buttons {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  justify-content: space-between;
  align-items: center;
}

.rename_buttons {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-left: 8px;
}

.rename_buttons svg {
  height: 16px;
  width: 16px;
}

.rename_buttons svg:nth-child(1) {
  color: #2aa87e;
}

.rename_buttons svg:nth-child(2) {
  color: #5b706f;
}

.more_info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  font-family: Lexend;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  text-align: left;
  color: #5b706f;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.more_info svg {
  height: 16px;
  width: 16px;
}

.copy_new_link {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #ffffff;
  background-color: #2aa87e;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  border: none;
}

.copy_new_link svg {
  height: 16px;
  width: 16px;
}

.collapseIcon {
  cursor: pointer;
}

.learner_scroll {
  max-height: 72vh;
  overflow-y: auto;
}

.button_section {
  display: flex;
  flex-direction: row;

  align-items: center;
  gap: 16px;
}

.upload_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;

  padding: 12px 16px;
  border-radius: 4px;
  border: 1px solid #5b706f;
  background: #ffffff;
  color: #5b706f;
  cursor: pointer;
}

.upload_button svg {
  height: 20px;
  width: 20px;
}

.upload {
  display: flex;
  flex-direction: column;

  width: Fill (808px) px;
  height: Hug (159px) px;
  padding: 24px;
  gap: 16px;
  border-radius: 4px;
  border: 1px dashed #c1c1ce;
  opacity: 0px;
  background: #f3f3f8;
  margin-top: 20px;
}
.upload_header {
  display: flex;
  flex-direction: row;

  align-items: center;
  gap: 16px;
}

.upload_header h4 {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  margin: 0;
  color: #5b706f;
}

.upload_header p {
  font-family: Lexend;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  text-align: left;
}

.upload_header svg {
  height: 50px;
  width: 50px;
  color: #5b706f;
}
.border {
  border: 1px solid var(--global-outline-grey, #dae8e7);
  margin: 0;
  width: 100%;
}

.upload_bottom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.upload_bottom p {
  font-family: Lexend;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  text-align: left;
  color: #5b706f;
  width: 25%;
}

.upload_bottom svg {
  height: 16px;
  width: 16px;
  color: #5b706f;
  cursor: pointer;
}
@media (max-width: 425px) {
  .content {
    padding: 0 16px;
  }
  .learner_header h2 {
    font-size: 17px;
  }
  .learner_header {
    flex-direction: column;
    gap: 16px;
  }
  .search {
    width: 90%;
  }
  .learner_header_buttons {
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }

  .coach_roaster {
    padding: 20px;
  }

  .learner_table thead th {
    font-size: 10px;
  }

  .modal_view {
    width: 80%;
  }
}
