.main {
  display: flex;
  flex-direction: row;
  background: var(--global-light-grey, #f3f8f8);
}

.content {
  padding: 0 35px;
  width: 90%;
}

.content h1 {
  color: var(--Global-Grey, #243030);

  font-family: Lexend;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
  letter-spacing: -0.087px;
  margin: 0;
}

.content p {
  color: var(--global-body-grey, #5b706f);
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
}
.coach_roaster {
  display: flex;
  padding: 36px;
  flex-direction: row;
  align-items: flex-start;
  gap: 32px;
  border-radius: 8px;
  border: 1px solid var(--global-outline-grey, #dae8e7);
  background: var(--global-white, #fff);
  margin-top: 20px;
}
.learner_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.learner_header h2 {
  color: var(--Global-Dark-Grey, #0a1214);
  font-family: Lexend;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
  letter-spacing: -0.075px;
}
.border {
  border: 1px solid var(--global-outline-grey, #dae8e7);

  margin-top: 10px;
  margin-bottom: 30px;
}

.top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.icons {
  display: flex;
  flex-direction: row;
  color: var(--global-form-grey, #8ca19f);
  text-align: right;
  gap: 8px;
}

.student_profile {
  display: flex;
  flex-direction: row;

  align-items: center;
  gap: 10px;
}

.student_profile img {
  width: 32px;
  height: 32px;
}

.student_profile span {
  color: #00a99d;
}
.post_reply {
  gap: 8px;
  color: '#4C12A1';
  cursor: pointer;
  border: none;
}

.post_reply ol li {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 300;
  line-height: 28px;
  text-align: left;
  color: #5b706f;
}

.post_reply ol {
  padding-left: 20px;
}
.post_reply a {
  color: #00a99d;

  font-family: Lexend;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  border: none;
}
.disableReplyButton {
  gap: 8px;
  color: var(--global-body-grey, #5b706f);
  cursor: not-allowed;
  border: none !important;
}

.reply svg {
  width: 16px;
  height: 16px;
  color: var(--nftenfte-purple, #4c12a1);
}

.button_section button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
  align-items: center;
}

.button_section svg {
  width: 16px;
  height: 16px;
}
.reply_section {
  position: absolute;
  bottom: 16px;
  left: 16px;
  right: 16px;
}

.bottom_section {
  position: absolute;
  bottom: 16px;
  left: 16px;
  right: 16px;
}

.coach_roaster_left {
  position: relative;
  height: 80vh;
  min-width: 17%;
}

.coach_roaster_left > p {
  position: absolute;
  bottom: 0;
}

.coach_roaster_left_bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.coach_roaster_left_bottom button {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  padding: 14px 16px;
  background: #4c12a1;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}
.coach_roaster_left ul {
  width: 100%;
}

.coach_roaster_left li p {
  cursor: pointer;
}

.coach_roaster_right {
  border-left: 1px solid #dae8e7;
  padding-left: 50px;
  position: relative;
  height: 80vh;
  width: 70%;
}

.post_reply_input {
  position: absolute;
  bottom: 0;
  left: 45px;
  right: 0;
}

.student_profile h4 {
  margin: 0;
}

.coach_roaster_right > svg {
  position: absolute;
  top: 50%;
  left: -21px;
}

.coach_roaster_left h2 {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  margin: 0;
}
.coach_roaster_left ul {
  padding: 0;
  margin: 0;
}

.coach_roaster_left ul li {
  list-style: none;
}
.coach_roaster_left ul li h5 {
  color: #8ca19f;

  font-family: Lexend;
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  text-align: left;
}

.coach_roaster_left ul li p {
  font-family: Lexend;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  text-align: left;
}

.document_section {
  display: flex;
  flex-direction: row;

  gap: 8px;
  margin-top: 10px;
  margin-bottom: 24px;
}
.document_section span {
  width: Hug (182px) px;
  height: Hug (29px) px;
  padding: 4px 16px 4px 16px;
  gap: 8px;
  border-radius: 16px;
  border: 1px solid #dae8e7;
  color: #5b706f;
  font-family: Lexend;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  text-align: left;
  background: #f3f8f8;
}

.document_section span a {
  text-decoration: none;
  color: #5b706f;
  font-family: Lexend;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  text-align: left;
  background: #f3f8f8;
}

.post_reply h6 {
  color: #243030;
  margin: 0;
  font-family: Lexend;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}

.remove_modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 448px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #dae8e7;
  padding: 36px;
  display: flex;
  flex-direction: column;
  outline: none;
  gap: 16px;
}

.remove_modal h2 {
  margin: 0;
}

.remove_modal button {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  padding: 14px 16px;
  background: #4c12a1;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.remove_modal button:disabled {
  background: #f3f8f8;
  color: #5b706f;
  cursor: not-allowed;
}

.thread_section {
  overflow-y: scroll;
  height: 62vh;
}

.thread_active {
  background: #f3f8f8;
  padding: 4px 8px;
  color: #5b706f;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  justify-content: space-between;
}

.thread_active ul {
  padding: 8px;
  margin: 0;
  position: absolute;
  bottom: -100px;
  left: 93%;
  background-color: #fff;
  width: 25%;
  box-shadow: 0px 8px 32px 0px rgba(60, 44, 99, 0.125);
  border: 1px solid rgba(218, 232, 231, 1);
  z-index: 1;
  border-radius: 8px;
}

.thread_active ul li {
  list-style: none;
  padding: 8px 16px;
  margin-top: 8px;
}

.thread_active ul li:hover {
  background: #f3f8f8;
}

.create_chat_button {
  margin-top: 16px;
  font-family: Lexend;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  text-align: left;
  padding: 8px;
  background: none;
  border: 1px solid #4c12a1;
  color: #4c12a1;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
