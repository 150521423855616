.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.container > div {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.signinForm {
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 450px;
}

.login_container {
  border-radius: 8px;
  border: 1px solid var(--global-outline-grey, #dae8e7);
  background: var(--global-white, #fff);
  /* Aura Shadow */
  box-shadow: 0px 16px 64px 0px rgba(10, 18, 20, 0.13);
  padding: 48px 24px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 520px;
  box-sizing: border-box;
  gap: 16px;
}

.login_container img {
  width: 104px;
  height: auto;
  margin-bottom: 24px;
}

.login_container h1 {
  color: var(--nftenfte-violet, #32229c);
  text-align: center;

  /* TierOneAI/H4 */
  font-family: Lexend;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%; /* 35px */
  letter-spacing: -0.087px;
  margin-top: 0;
  margin-bottom: 12px;
}

.login_container h3 {
  color: var(--global-body-grey, #5b706f);
  text-align: center;

  /* TierOneAI/P */
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%; /* 28px */
  margin-top: 0;
  margin-bottom: 32px;
}

.loginText {
  color: var(--global-body-grey, #4c12a1);
  text-align: center;
  margin-top: 24px;
  text-decoration: none;
}

.signInButton {
  background: #4c12a1;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  margin-top: 20px;
}

.orText {
  color: #88888f;
  text-align: center;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
  position: relative;
}

.orText:before {
  content: ' ';
  height: 2px;
  width: 65px;
  background: #e7e7ed;
  display: block;
  position: absolute;
  left: 20px;
  top: 50%;
}

.orText:after {
  content: ' ';
  height: 2px;
  width: 65px;
  background: #e7e7ed;

  display: block;
  position: absolute;
  right: 20px;
  top: 50%;
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: flex-end;
}

.branding {
  background: var(--nftenfte-dark-blue, #055370);
}

.branding h1 {
  color: var(--global-white, #fff);

  /* TierOneAI/H4 */
  font-family: Lexend;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%; /* 35px */
  letter-spacing: -0.087px;
  margin-top: 0;
  margin-bottom: 24px;
}

.branding h3 {
  color: var(--global-light-grey, #f3f8f8);

  /* TierOneAI/P */
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%; /* 28px */
  margin-top: 0;
  margin-bottom: 32px;
}

.branding a {
  color: var(--global-white, #fff);
  display: inline-block;

  /* TierOneAI/Button */
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
  border-radius: 4px;
  border: 1px solid var(--global-white, #fff);
  padding: 12px 24px;
  cursor: pointer;
}

.brainding_section {
  max-width: 450px;
}

.error {
  color: var(--global-error-red, #e74c3c);
  text-align: center;
  margin-top: 24px;
}

@media screen and (max-width: 768px) {
  .container .branding {
    display: none;
  }
}
