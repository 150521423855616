.app-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.header-text {
  display: flex;
  font-family: 'Roobert';
  font-style: normal;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.sub-text {
  font-family: 'Roobert';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 144%;
  text-align: center;
  letter-spacing: -0.0125em;
  color: #4f4c4d;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  order: 0;
  flex-grow: 0;
  position: relative;
}

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 36px;
  gap: 12px;
  width: 267px;
  height: 64px;
  background: #00bf8c;
  border-radius: 2px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.clear-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 18px 36px;
  gap: 12px;
  width: 267px;
  height: 64px;
  background: #fff;
  border-radius: 2px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-top: 15px;
}

.button:hover {
  cursor: pointer;
}

.button-text {
  font-family: 'Roobert';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 144%;
  text-align: center;
  hanging-punctuation: first last;
  color: #231f20;
}

.clear-button:hover {
  cursor: pointer;
}
