.header h4 {
  font-family: Lexend;
  font-size: 28px;
  font-weight: 300;
  line-height: 35px;
  letter-spacing: -0.0031em;
  text-align: left;
}

.header {
  border-bottom: 1px solid #dae8e7;
  margin-bottom: 16px;
}

.roster_section {
  background-color: #fff;
  border-radius: 8px;
  padding: 24px;
}

.roster_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: center;
}

.roster_nav {
  width: 86%;
  border-bottom: 1px solid #0aa6e0;
}

.roster_nav_active {
  color: #0aa6e0;
  border-bottom: 2px solid #0aa6e0;
}

.roster_header ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.roster_header ul li {
  cursor: pointer;
}

.roster_table table {
  margin-top: 16px;
}

.roster_table table,
.roster_table thead {
  width: 100%;
  display: block;
}

.roster_table tr {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
}

.roster_table tr th,
.roster_table tr td {
  width: 20%;
}

.roster_table tr th:last-child,
.roster_table tr td:last-child {
  text-align: right;
}

.roster_table tr th {
  font-family: Lexend;
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #0a1214;
}

.roster_table thead tr {
  border-bottom: 1px solid #dae8e7;
}

.roster_table tr td {
  font-family: Lexend;
  font-size: 14px;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #5b706f;
}
