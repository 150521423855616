.main {
  display: flex;
  flex-direction: row;
  background: var(--global-light-grey, #f3f8f8);
}

.content {
  height: 100%;
  min-height: 100vh;
  padding: 0 35px;
  width: 90%;
}

.content h1 {
  color: var(--Global-Grey, #243030);

  /* TierOneAI/H4 */
  font-family: Lexend;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%; /* 35px */
  letter-spacing: -0.087px;
}

.content p {
  color: var(--global-body-grey, #5b706f);
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
}

.learner_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.learner_header h2 {
  color: var(--Global-Dark-Grey, #0a1214);
  font-family: Lexend;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
  letter-spacing: -0.075px;
}
.coach_roaster {
  display: flex;

  padding: 36px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  border-radius: 8px;
  border: 1px solid var(--global-outline-grey, #dae8e7);
  background: var(--global-white, #fff);
  margin-top: 20px;
}

.learner_table {
  display: flex;
  flex-direction: column;
  width: 100%;

  overflow-x: auto;
}

.learner_table table {
  border-spacing: 0;
}
.learner_table th {
  border-bottom: 1px solid var(--global-outline-grey, #dae8e7);
}
.learner_table thead th {
  padding-bottom: 10px;
  text-align: center;
  color: var(--Global-Grey, #243030);
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 175%;
  width: 15%;
}

.learner_table thead th:first-child {
  text-align: left;
  width: 40%;
}

.learner_table thead th:last-child {
  text-align: right;
}

.learner_table table tr td {
  text-align: center;
}
.learner_table table tr td:first-child {
  text-align: left;
}

.learner_table table tr {
  padding-bottom: 20px;
}

.learner_table table tr.coachrow td {
  padding: 16px 0;
}

.data {
  color: var(--global-body-grey, #5b706f);
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
}

.status {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-end;
}

.status button {
  all: unset;
  color: var(--nftenfte-dark-blue, #055370);
  cursor: pointer;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
}

.status img {
  width: 16px;
  height: 16px;
}

.resend {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.resend svg {
  width: 16px;
  height: 16px;
  color: var(--NFTE-NFTE-Purple, #4c12a1);
}

.resend button,
.resend a {
  color: var(--NFTE-NFTE-Purple, #4c12a1);

  /* TierOneAI/Element */
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%; /* 24.5px */
  text-decoration: none;
}

.empty_table {
  width: 100%;
  border: 1px solid var(--global-outline-grey, #dae8e7);
  background: var(--global-light-grey, #f3f8f8);
  padding: 36px 0px;
}
.empty_table p {
  text-align: center;
}

.link {
  color: var(--NFTE-NFTE-Purple, #4c12a1) !important;

  font-family: Lexend;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
  letter-spacing: -0.075px;
  cursor: pointer;
}
.search {
  width: 512px;

  background: #fff;
}

.learner_header_buttons {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}
.iteration {
  display: flex;
  height: 48px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--NFTE-NFTE-Purple, #4c12a1);
  color: #fff;
  border: none;
  cursor: pointer;
}
.iteration svg {
  width: 16px;
  height: 16px;
}

.review,
.progress,
.done {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: center;
}
.review svg,
.progress svg,
.done svg {
  width: 16px;
  height: 16px;
}

.progress {
  color: #f6a623;
}

.review {
  fill: #a57ff6;
  color: #a57ff6;
}

.done {
  color: #30bf8f;
}

.border {
  border: 1px solid var(--global-outline-grey, #dae8e7);

  margin-top: 10px;
  margin-bottom: 30px;
}

.name_input {
  width: 100%;
}

.start {
  display: flex;
  height: 48px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--NFTE-NFTE-Purple, #4c12a1);
  color: #fff;
  border: none;
  cursor: pointer;
  width: 100%;
}

.start:disabled {
  background: var(--global-light-grey, #f3f8f8);
  color: var(--global-body-grey, #5b706f);
  cursor: not-allowed;
}

.modal_content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.modal_content h2 {
  font-family: Lexend;
  font-size: 20px;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: -0.0031em;
  text-align: left;
}
.modal_view {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(155, 151, 151, 0.2);
}

.modal_upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal_upload h2 {
  font-family: Lexend;
  font-size: 24px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: -0.0031em;
  text-align: center;
  margin: 0;
}

.modal_upload p {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 300;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
}

.ok_button {
  display: flex;
  height: 48px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #5b706f;

  color: #fff;
  border: none;
  cursor: pointer;
  width: 100%;
}

.tag_year {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: center;
  font-family: Lexend;
}

.tag_year span {
  background-color: #f3f8f8;
  padding: 2px 12px;
  border-radius: 48px;
  border: 1px solid #dae8e7;
  color: #5b706f;
  font-size: 12px;
  font-weight: 300;
}

.data p {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: flex-start;
}

.error {
  margin-left: 2px;
}
.error svg {
  color: #e03838;
}

.progress svg {
  color: #055370;
}

.complete svg {
  color: #30bf8f;
}
.data p svg {
  width: 16px;
  height: 16px;
}

.submit_bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #dae8e7;
  padding: 8px 16px;
  border-radius: 8px;
}

.submit_bar button {
  all: unset;
  color: #fff;
  background: #4c12a1;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.apply_button {
  all: unset;
  color: #4c12a1;
  border: 1px solid #4c12a1;
  background: transparent;
  padding: 8px 32px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: Lexend;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
}
.apply_button svg {
  width: 16px;
  height: 16px;
}

.submit_bar span {
  font-family: Lexend;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
}

.input_fields {
  display: flex;
  flex-direction: row;
  gap: 16px;

  width: 100%;
}
.date_range_picker input {
  height: 51px;
}

.modal_title h5 {
  color: var(--TierOneAI-Grey, #243030);
  text-align: center;
  font-family: Lexend;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
  letter-spacing: -0.075px;
  margin: 0;
}

.modal_title p {
  color: var(--TierOneAI-Body-Grey, #5b706f);
  text-align: center;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
}

.remove {
  display: flex;
  height: 48px;
  padding: 8px 36px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  background: var(--TierOneAI-Error-Red, #e03838);
  color: var(--Global-White, #fff);
  cursor: pointer;
  border: none;
}

.cancel {
  display: flex;
  height: 48px;
  padding: 8px 36px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid var(--Global-Grey, #243030);
  background: var(--TierOneAI-White, #fff);

  cursor: pointer;
}

.modal_buttons {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-top: 30px;
}

@media (max-width: 425px) {
  .content {
    padding: 0 16px;
  }
  .learner_header h2 {
    font-size: 17px;
  }
  .learner_header {
    flex-direction: column;
    gap: 16px;
  }
  .search {
    width: 90%;
  }
  .learner_header_buttons {
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }

  .coach_roaster {
    padding: 20px;
  }

  .learner_table thead th {
    font-size: 10px;
  }

  .modal_view {
    width: 80%;
  }
}
