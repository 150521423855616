.main {
  display: flex;
  flex-direction: row;
  background: var(--global-light-grey, #f3f8f8);
  /* min-height: 100vh; */
}

.content {
  height: 100%;
  min-height: 100vh;
  padding: 0 50px;
  width: 90%;
}

.content h1 {
  color: var(--Global-Grey, #243030);

  /* TierOneAI/H4 */
  font-family: Lexend;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%; /* 35px */
  letter-spacing: -0.087px;
}

.content p {
  color: var(--global-body-grey, #5b706f);
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
}

.border {
  border: 1px solid var(--global-outline-grey, #dae8e7);

  margin-top: 10px;
  margin-bottom: 30px;
}

.button_section {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.button_section button {
  border-radius: 8px;
  padding: 10px 20px;
  color: #5b706f;
  font-family: Lexend;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;

  cursor: pointer;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.header h2 {
  font-family: Lexend;
  font-size: 28px;
  font-weight: 300;
  line-height: 35px;
  letter-spacing: -0.0031em;
  text-align: left;
}

.body {
  display: flex;
  flex-direction: row;
  gap: 80px;
  margin-top: 20px;
  background: #fff;
  padding: 50px;
  border-radius: 8px;
}
.document {
  width: 100%;
  overflow-y: scroll;
  height: 80vh;
  box-shadow: 2px 1px 5px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.course_list {
  width: 100%;
}
.course_list ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  gap: 50px;
  border-bottom: 1px solid var(--NFTE-NFTE-Blue, #0aa6e0);
  /* margin-top: 24px; */
  width: 100%;
}

.course_list li {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  cursor: pointer;

  font-family: Lexend;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  width: 50%;
}

.course_list li.active {
  border-bottom: 4px solid var(--NFTE-NFTE-Blue, #0aa6e0);
}

.course_list li p {
  color: var(--Global-Grey, #243030);
  /* TierOneAI/Fine Print Bold */
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
}
.container {
  background: var(--Global-White, #fff);
  overflow-y: scroll;

  position: relative;
  height: 85vh;
  padding: 0 16px;
  box-shadow: 2px 1px 5px rgba(0, 0, 0, 0.4);
}

.container p {
  color: var(--Global-Body-Grey, #5b706f);

  /* TierOneAI/P */
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%; /* 28px */
}
.container_head h2 {
  font-family: Lexend;
  font-size: 28px;
  font-weight: 300;
  line-height: 35px;
  letter-spacing: -0.0031em;
  text-align: left;
  margin-top: 0;
}

.container_head p {
  font-family: Lexend;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}
.analyzer_section {
  width: 100%;
}

/* .intro {
  overflow-y: scroll;
  height: 70vh;
} */
.intro h4 {
  color: #055370;
  font-family: Lexend;
  font-size: 24px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: -0.0031em;
  text-align: left;
}

.intro h5 {
  color: #00a99d;
  font-family: Lexend;
  font-size: 20px;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: -0.0031em;
  text-align: left;
  margin: 0;
}

.intro p {
  color: var(--Global-Body-Grey, #5b706f);

  font-family: Lexend;
  font-size: 16px;
  font-weight: 300;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}

.research_table table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;
}

.research_table td {
  border: 1px solid #f3f8f8;
  text-align: left;
  padding: 8px;
}

.research_table tr:nth-child(even) {
  background-color: #f3f8f8;
}

.dropDown {
  width: 100%;
  margin-top: 20px;
}

.comment {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  justify-content: center;
  color: var(--global-body-grey, #5b706f);

  cursor: pointer;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
}

.selectComment {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  justify-content: center;
  color: #4c12a1;

  cursor: pointer;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
}

.selectComment svg {
  color: #4c12a1;
}

.top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.icons {
  display: flex;
  flex-direction: row;
  color: var(--global-form-grey, #8ca19f);
  text-align: right;
  gap: 8px;
}

.student_profile {
  display: flex;
  flex-direction: row;

  align-items: center;
  gap: 10px;
}

.student_profile img {
  width: 32px;
  height: 32px;
}

.student_profile span {
  color: #00a99d;
}
.post_reply {
  gap: 8px;
  color: '#4C12A1';
  cursor: pointer;
  border: none;
}
.post_reply a {
  color: #00a99d;

  font-family: Lexend;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  border: none;
}
.disableReplyButton {
  gap: 8px;
  color: var(--global-body-grey, #5b706f);
  cursor: not-allowed;
  border: none !important;
}

.reply svg {
  width: 16px;
  height: 16px;
  color: var(--nftenfte-purple, #4c12a1);
}

.button_section button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
  align-items: center;
}

.button_section svg {
  width: 16px;
  height: 16px;
}

.modal_comment_section {
  margin-top: 20px;
  max-height: 70vh;
}
.modal_comment_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.modal_comment_header p {
  color: var(--TierOneAI-Dark-Grey, #0a1214);
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
  letter-spacing: -0.062px;
}

.modal_comment_header span {
  color: var(--TierOneAI-Body-Grey, #5b706f);
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.modal_border {
  border: 1px solid var(--Global-Outline-Grey, #dae8e7);
}
.post_body_head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.post_body_head_left,
.post_body_image,
.role {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  gap: 12px;
  justify-content: center;
}

.post_body_head img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.profile h4 {
  color: var(--global-grey, #243030);

  margin: 0;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 175%;
}
.profile p {
  color: var(--global-body-grey, #5b706f);

  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  margin: 0;
}

.post_body_head_left span {
  border-radius: 48px;
  border: 1px solid var(--global-grey, #243030);
  opacity: 0.8;
  background: var(--global-grey, #243030);
  display: flex;
  padding: 2px 12px;
  align-items: center;
  gap: 4px;

  color: var(--global-white, #fff);
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.post_date h4 {
  color: var(--global-body-grey, #5b706f);
  text-align: right;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
  margin: 0;
}

.post_date p {
  color: var(--global-body-grey, #5b706f);
  text-align: right;
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  margin: 0;
}

.modal_comment_body p {
  color: var(--TierOneAI-Body-Grey, #5b706f);
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
}

.modal_bottom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  gap: 12px;
}

.reply {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;

  background: #4c12a1;

  color: var(--Global-White, #fff);
  cursor: pointer;
  border: none;
  width: 100%;
}

.collapse {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
  background: var(--TierOneAI-Light-Grey, #f3f8f8);
  color: #4c12a1;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  border: none;
  width: 100%;
}

.generate svg {
  width: 16px;
  height: 16px;
}

.reply_field {
  width: 100%;
}
/* 
.aiChat {
  height: 80vh;
  overflow-y: scroll;
} */

.reply_section {
  position: absolute;
  bottom: 16px;
  left: 16px;
  right: 16px;
}

.bottom_section {
  position: absolute;
  bottom: 16px;
  left: 16px;
  right: 16px;
}
