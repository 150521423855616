.main {
  width: 100%;
  background: var(--global-light-grey, #f3f8f8);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  padding: 0 35px;
  width: 95%;
  background-color: var(--global-light-grey, #f3f8f8);
  max-width: 1600px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content h1 {
  color: var(--Global-Grey, #243030);
  font-family: Lexend;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: -0.087px;
}

.border {
  border: 1px solid var(--global-outline-grey, #dae8e7);

  margin-top: 10px;
  margin-bottom: 30px;
}

.nav_btn {
  border: 1px solid #5b706f;
  padding: 0 15px;
  border-radius: 5px;
  width: fit-content;
  display: flex;
  gap: 8px;
  align-items: center;
  background: none;
  outline: none;
  height: 40px;
  color: var(--Global-Grey, #5b706f);
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  text-wrap: nowrap;
  text-decoration: none;
}

.nav_btn:hover {
  background-color: #586464;
  color: #fff;
}
.navbar_container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.navbar {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  height: 60px;
  justify-content: space-between;
}

@media (max-width: 425px) {
  .content {
    padding: 0 16px;
  }
  .learner_header h2 {
    font-size: 17px;
  }
  .learner_header {
    flex-direction: column;
    gap: 16px;
  }
  .search {
    width: 90%;
  }
  .learner_header_buttons {
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }

  .coach_roaster {
    padding: 20px;
  }

  .learner_table thead th {
    font-size: 10px;
  }

  .modal_view {
    width: 80%;
  }
}

.container {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: #fff;
  align-items: start;
  padding: 20px;
  gap: 40px;
}
.nav_right {
  display: flex;
  align-items: center;
  gap: 10px;
}
/* page header ends here */
.container_split {
  width: 100%;
  display: flex;
  align-items: start;
  gap: 15px;
}
.container_left {
  display: flex;
  width: 100%;

  flex-direction: column;
}
.container_right {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.call_history_navbar {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  width: 97%;
  padding: 0 10px;
}
.nav_button_group {
  display: flex;
  align-items: center;
  gap: 15px;
}
.call_history_navbar h1 {
  font-size: 20px;
  font-weight: 300;
  color: var(--Global-Grey, #243030);
  font-family: Lexend;
}
.nav_button_container {
  display: flex;
  align-items: center;
  border: 1px solid #dae8e7;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  height: 40px;
  padding: 0 15px;
}

.call_history_container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
}
.summary_left_nav {
  display: flex;
  gap: 20px;
  align-items: center;
  width: 100%;
  justify-content: center;
}
