.content_section {
  font-family: 'Lexend';
  width: 80%;
  margin: 20px auto;
}

.header svg {
  margin: 0 auto;
}

.header h1 {
  font-family: Lexend;
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -0.0125em;
  text-align: left;
  margin-bottom: 0;
}

.header p {
  font-size: 14px;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #40806a;
  margin: 0;
}
.header {
  border-bottom: 1px solid #dae8e7;
  padding-bottom: 20px;
}
.content_section h3 {
  font-size: 28px;
  line-height: 60px;
  letter-spacing: -0.0125em;
  text-align: left;
  margin: 0;
  margin-bottom: 16px;
}

.content_section h4 {
  font-size: 24px;
  line-height: 35px;
  letter-spacing: -0.0031em;
  text-align: left;
  margin: 0;
  margin-bottom: 8px;
}

.content_section h5 {
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.0031em;
  text-align: left;
  margin: 0;
  margin-bottom: 8px;
}

.content_section p {
  font-size: 16px;
  font-weight: 300;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}

.content_section ul li h6 {
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.0031em;
  text-align: left;
  display: inline;
}

.content_section ul li p {
  font-size: 16px;
  font-weight: 300;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  display: inline;
}
