.main {
  width: 100%;
  background: var(--global-light-grey, #f3f8f8);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  padding: 0 35px;
  width: 95%;
  background-color: var(--global-light-grey, #f3f8f8);
  max-width: 1600px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content h1 {
  color: var(--Global-Grey, #243030);
  font-family: Lexend;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: -0.087px;
}
.container {
  margin-bottom: 30px;
  display: flex;
  width: 100%;
  background-color: #fff;
  flex-direction: column;
  align-items: start;
  height: 100%;
  padding: 20px;
  gap: 40px;
}
.border {
  border: 1px solid var(--global-outline-grey, #dae8e7);

  margin-top: 10px;
  margin-bottom: 30px;
}
.navbar {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  height: 60px;
  justify-content: space-between;
}
.nav_right {
  display: flex;
  align-items: center;
  gap: 10px;
}
.nav_btn1 {
  border: 1px solid #bbbfbf;
  padding: 0 15px;
  border-radius: 5px;
  width: fit-content;
  display: flex;
  gap: 8px;
  align-items: center;
}

.nav_btn2 {
  border: 1px solid #00706c;
  padding: 0 15px;
  border-radius: 5px;
  width: fit-content;
  display: flex;
  gap: 8px;
  align-items: center;
  background: none;
  outline: none;
  height: 43px;
  color: var(--Global-Grey, #00706c);
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  text-wrap: nowrap;
  text-decoration: none;
}

.nav_btn2:hover {
  background-color: #2aa87e;
  color: #fff;
}
.navbar_container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
@media (max-width: 425px) {
  .content {
    padding: 0 16px;
  }
  .learner_header h2 {
    font-size: 17px;
  }
  .learner_header {
    flex-direction: column;
    gap: 16px;
  }
  .search {
    width: 90%;
  }
  .learner_header_buttons {
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }

  .coach_roaster {
    padding: 20px;
  }

  .learner_table thead th {
    font-size: 10px;
  }

  .modal_view {
    width: 80%;
  }
}

/*----------------------------- more css start from there ----------------------------*/
.nav {
  display: flex;
  width: 100%;
}

.nav_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.nav_item {
  display: flex;
  gap: 5px;
  align-items: center;
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  color: #243030;
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  width: 100%;
  text-wrap: nowrap;
  line-height: 4px;
}
.nav_item:hover {
  color: #2aa87e;
}

.line {
  width: 99.97%;
  border: 1px solid var(--global-outline-grey, #00706c);
}

.scorecard {
  width: 95%;
  padding: 20px;
}
.scorecard_split {
  width: 100%;
  display: flex;
  gap: 20px;
}
.feedback_summary {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: start;
}
.total_score {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: start;
}
.underline {
  width: 99.97%;
  border: 1px solid var(--global-outline-grey, #dae8e7);
}
.feedback_summary h1 {
  color: #243030;
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  text-wrap: nowrap;
}

.total_score_header {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.total_score_header h1 {
  color: #243030;
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  text-wrap: nowrap;
}
.total_score_container {
  width: 95%;
  padding: 10px;
  border: 1px solid #dae8e7;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
  margin-left: 5px;
}
.total_score_title {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.total_score_title h2 {
  color: #243030;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  text-wrap: nowrap;
}
.attributes {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  color: #5b706f;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  text-wrap: nowrap;
}
.attributes_left {
  display: flex;
  gap: 10px;
  align-items: center;
}
.drawer_btn {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #5b706f;
}
.drawer_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.drawer_header_btn_group {
  display: flex;
  align-items: center;
  gap: 10px;
}
.drawer_btn {
  display: flex;
  gap: 10px;
  align-items: center;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  text-wrap: nowrap;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  line-height: 0;
  padding: 2px 10px;
  border-radius: 5px;
}
.drawer_header h1 {
  color: #141418;
  font-family: Lexend;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  text-wrap: nowrap;
}
.drawer_container {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
  width: 100%;
}

.media_container {
  display: flex;
  align-items: center;
}
.chat_history {
  display: flex;
  width: 98%;
  padding: 10px;
  flex-direction: column;
  gap: 5px;

  max-height: 85vh;
  overflow-x: hidden;
  overflow-y: scroll;
}

/* Message CSS starts here */
.message_container {
  display: flex;
  gap: 5px; /* Gap between avatar and message content */
  align-items: flex-start; /* Align items at the top */
  width: 98%;
  padding: 5px 10px;
}

/* Message content (message text and time) */
.message_content {
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 92%; /* Make sure the message doesn't take full width */
  width: 100%;
}

.message_content span {
  color: #5b706f;
  font-family: Lexend;
  font-size: 8px;
  font-weight: 300;
}

.message_content p {
  font-family: Lexend;
  font-size: 14px;
  font-weight: 300;
  word-break: break-word; /* Ensure text breaks properly in long messages */
}

/* Sender message: Align to the left, avatar on the left */
.sender_message {
  justify-content: flex-start; /* Content aligned to the left */
}
.sender_message .sender {
  border: 1px solid #dae8e7;
  padding: 1px 10px;
  border-radius: 5px;
  background-color: #f3f8f8;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.sender_message img {
  order: 0; /* Avatar stays on the left */
}

.sender_message .message_content {
  order: 1; /* Message content stays on the right */
  width: fit-content;
}

.sender_message p {
  background-color: #f3f8f8;
  color: #5b706f;
}

/* Receiver message: Align to the right, avatar on the right */
.receiver_message {
  justify-content: flex-end; /* Content aligned to the right */
}

.receiver_message img {
  order: 1; /* Avatar on the right */
}

.receiver_message .message_content {
  order: 0; /* Message content on the left */
  width: 100%;
  display: flex;
  align-items: center;
}

.receiver_message .sender {
  background-color: #5b706f;
  color: white;
  padding: 1px 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(49, 49, 49, 0.19);
  z-index: 2;
  width: 97%;
}

/* Avatar styling */
.message_container img {
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: 50%;
  margin-top: 5px;
}

/* Optional styling for the time */
.sender_message span {
  align-self: flex-start;
}
.receiver_message span {
  align-self: flex-end;
}
.sender {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;
}
.expand_attributes {
  width: 90%;
  border: 3px solid #00706c;
  margin-top: -10px;
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.expand_attributes h1 {
  color: #243030;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  text-wrap: nowrap;
}
/* Message CSS ends here */

.question_raised_container {
  width: 97%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: start;
}
.question_raised_header {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.question_raised_header p {
  color: #5b706f;
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  text-wrap: nowrap;
}
.raised_questions {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: start;
}
.raised_question_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
}
.raised_question_header {
  display: flex;
  gap: 15px;
  align-items: center;
}
.raised_question_header img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  object-fit: cover;
}
.raised_question_header p {
  color: #00706c;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.peer_answer_comparisons {
  border: 1px solid #dae8e7;
  background-color: #fff;
  border-radius: 5px;
  width: 96%;
  padding: 15px;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.peer_answer_comparisons_header {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #40806a;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.your_answer {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
}
.your_answer p {
  color: #243030;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
}
.your_answer h1 {
  font-weight: 500;
  color: #243030;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  line-height: 0;
}
.your_answer span {
  color: #5b706f;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 5px;
  margin-bottom: 5px;
}
.peer_answer {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
  margin: 15px 0;
}

.peer_answer p {
  color: #243030;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  margin-top: 5px;
}
.peer_answer h1 {
  font-weight: 500;
  color: #40806a;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  margin-top: 5px;
  line-height: 0;
}
.media_player {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: start;
}
.no_audio {
  font-weight: 300;
  color: #868887;
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
}
