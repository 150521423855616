.main {
  display: flex;
  flex-direction: row;
  background: var(--global-light-grey, #f3f8f8);
  /* min-height: 100vh; */
}

.content {
  height: 100%;
  min-height: 100vh;
  padding: 0 50px;
  width: 90%;
}

.content h1 {
  color: var(--Global-Grey, #243030);

  /* TierOneAI/H4 */
  font-family: Lexend;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%; /* 35px */
  letter-spacing: -0.087px;
}

/* .content p {
  color: var(--global-body-grey, #5b706f);
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
} */

.border {
  border: 1px solid var(--global-outline-grey, #dae8e7);

  margin-top: 10px;
  margin-bottom: 30px;
}

.button_section {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.button_section button {
  border-radius: 8px;
  padding: 8px 20px;
  color: #5b706f;
  font-family: Lexend;
  font-size: 16px;
  font-weight: 500;
  height: 40px;
  letter-spacing: 0em;
  text-align: center;
  border: 1px solid #969898;
  cursor: pointer;
  background: none;
}

.approve {
  background: #2aa87e;
  color: #fff;
  border: none;
}

.missing {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 300;
  line-height: 28px;
  text-align: left;
  margin: 0;
  color: #5b706f;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header h2 {
  font-family: Lexend;
  font-size: 28px;
  font-weight: 300;
  line-height: 35px;
  letter-spacing: -0.0031em;
  text-align: left;
}

.body {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 20px;
  background: #fff;
  padding: 50px;
  border-radius: 8px;
  height: 142vh;
}
.document {
  width: 100%;
  overflow-y: scroll;
  height: 80vh;
  box-shadow: 2px 1px 5px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.course_list {
  width: 100%;
}
.course_list ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  gap: 50px;
  border-bottom: 1px solid var(--NFTE-NFTE-Blue, #0aa6e0);
  /* margin-top: 24px; */
  width: 100%;
}

.course_list li {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  cursor: pointer;

  font-family: Lexend;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  width: 50%;
}

.course_list li.active {
  border-bottom: 4px solid var(--NFTE-NFTE-Blue, #0aa6e0);
}

.course_list li p {
  color: var(--Global-Grey, #243030);
  /* TierOneAI/Fine Print Bold */
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
}
.container {
  background: var(--Global-White, #fff);
  overflow-y: scroll;

  position: relative;
  height: 85vh;
  padding: 0 16px;
  box-shadow: 2px 1px 5px rgba(0, 0, 0, 0.4);
}

.container p {
  color: var(--Global-Body-Grey, #5b706f);

  /* TierOneAI/P */
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%; /* 28px */
}
.container_head h2 {
  font-family: Lexend;
  font-size: 28px;
  font-weight: 300;
  line-height: 35px;
  letter-spacing: -0.0031em;
  text-align: left;
  margin-top: 0;
}

.container_head p {
  font-family: Lexend;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}
.analyzer_section {
  width: 100%;
}

/* .intro {
  overflow-y: scroll;
  height: 70vh;
} */
.intro h4 {
  color: #055370;
  font-family: Lexend;
  font-size: 24px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: -0.0031em;
  text-align: left;
}

.intro h5 {
  color: #00a99d;
  font-family: Lexend;
  font-size: 20px;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: -0.0031em;
  text-align: left;
  margin: 0;
}

.intro p {
  color: var(--Global-Body-Grey, #5b706f);

  font-family: Lexend;
  font-size: 16px;
  font-weight: 300;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}

.research_table table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;
}

.research_table td {
  border: 1px solid #f3f8f8;
  text-align: left;
  padding: 8px;
}

.research_table tr:nth-child(even) {
  background-color: #f3f8f8;
}

.dropDown {
  width: 100%;
  margin-top: 20px;
}

.comment {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  justify-content: center;
  color: var(--global-body-grey, #5b706f);

  cursor: pointer;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
}

.selectComment {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  justify-content: center;
  color: #4c12a1;

  cursor: pointer;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
}

.selectComment svg {
  color: #4c12a1;
}

.top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.icons {
  display: flex;
  flex-direction: row;
  color: var(--global-form-grey, #8ca19f);
  text-align: right;
  gap: 8px;
}

.student_profile {
  display: flex;
  flex-direction: row;

  align-items: center;
  gap: 10px;
}

.student_profile img {
  width: 32px;
  height: 32px;
}

.student_profile span {
  color: #00a99d;
}
.post_reply {
  gap: 8px;
  color: '#4C12A1';
  cursor: pointer;
  border: none;
}
.post_reply a {
  color: #00a99d;

  font-family: Lexend;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  border: none;
}
.disableReplyButton {
  gap: 8px;
  color: var(--global-body-grey, #5b706f);
  cursor: not-allowed;
  border: none !important;
}

.reply svg {
  width: 16px;
  height: 16px;
  color: var(--nftenfte-purple, #4c12a1);
}

.button_section button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
  align-items: center;
}

.button_section svg {
  width: 16px;
  height: 16px;
}

.modal_comment_section {
  margin-top: 20px;
  max-height: 70vh;
}
.modal_comment_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.modal_comment_header p {
  color: var(--TierOneAI-Dark-Grey, #0a1214);
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
  letter-spacing: -0.062px;
}

.modal_comment_header span {
  color: var(--TierOneAI-Body-Grey, #5b706f);
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.modal_border {
  border: 1px solid var(--Global-Outline-Grey, #dae8e7);
}
.post_body_head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.post_body_head_left,
.post_body_image,
.role {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  gap: 12px;
  justify-content: center;
}

.post_body_head img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.profile h4 {
  color: var(--global-grey, #243030);

  margin: 0;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 175%;
}
.profile p {
  color: var(--global-body-grey, #5b706f);

  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  margin: 0;
}

.post_body_head_left span {
  border-radius: 48px;
  border: 1px solid var(--global-grey, #243030);
  opacity: 0.8;
  background: var(--global-grey, #243030);
  display: flex;
  padding: 2px 12px;
  align-items: center;
  gap: 4px;

  color: var(--global-white, #fff);
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.post_date h4 {
  color: var(--global-body-grey, #5b706f);
  text-align: right;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
  margin: 0;
}

.post_date p {
  color: var(--global-body-grey, #5b706f);
  text-align: right;
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  margin: 0;
}

.modal_comment_body p {
  color: var(--TierOneAI-Body-Grey, #5b706f);
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
}

.modal_bottom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  gap: 12px;
}

.reply {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;

  background: #4c12a1;

  color: var(--Global-White, #fff);
  cursor: pointer;
  border: none;
  width: 100%;
}

.collapse {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
  background: var(--TierOneAI-Light-Grey, #f3f8f8);
  color: #4c12a1;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  border: none;
  width: 100%;
}

.generate svg {
  width: 16px;
  height: 16px;
}

.reply_field {
  width: 100%;
}
/* 
.aiChat {
  height: 80vh;
  overflow-y: scroll;
} */

.reply_section {
  position: absolute;
  bottom: 16px;
  left: 16px;
  right: 16px;
}

.bottom_section {
  position: absolute;
  bottom: 16px;
  left: 16px;
  right: 16px;
}

.alert_text {
  display: flex;
  flex-direction: column;
  width: 400px;

  padding: 24px 32px 24px 32px;
  border-radius: 4px 0px 0px 0px;
  border: 1px solid #e03838;
  color: red;

  font-family: Lexend;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
}

.success_text {
  display: flex;
  flex-direction: column;
  width: 400px;

  padding: 24px 32px 24px 32px;
  border-radius: 4px 0px 0px 0px;
  border: 1px solid green;
  color: red;

  font-family: Lexend;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
}

.alert_error,
.alert_error_incomplete {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.alert_error p,
.alert_error_incomplete p {
  margin: 8px;
}

.alert_error_incomplete svg {
  height: 20px;
  width: 20px;
  fill: #ed7e00;
}

.alert_error_incomplete {
  color: #ed7e00;
}

.alert_text_content {
  color: black;
  font-family: 'Lexend';
}

.alert_text_content h4 {
  font-weight: 300;
  font-size: 20px;
  color: #243030;
}

.alert_text_content p {
  font-weight: 300;
  color: #5b706f;
  font-size: 16px;
}

.alert_text_content ul {
  padding: 16px 20px;
}

.alert_text_content ul li {
  font-weight: 300;
  color: #5b706f;
  font-size: 16px;
}
.alert_box {
  align-items: center;
  justify-content: center;
  width: 400px;

  padding: 24px 32px 24px 32px;
  gap: 16px;
  border-radius: 4px 0px 0px 0px;
  border: 1px solid #e03838;

  font-family: Lexend;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
}
.alert_text svg {
  height: 20px;
  width: 20px;
  margin-bottom: 25px;
}

.custom_modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 448px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #dae8e7;
  padding: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  outline: none;
  gap: 16px;
}

.modal_button {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-top: 30px;
}

.modal_header {
  color: var(--TierOneAI-Grey, #243030);
  text-align: center;

  /* TierOneAI/H5 */
  font-family: Lexend;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%; /* 30px */
  letter-spacing: -0.075px;
  margin: 0;
}

.modal_description {
  color: var(--TierOneAI-Body-Grey, #5b706f);
  text-align: center;

  /* TierOneAI/P */
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%; /* 28px */
  margin: 0;
}

.modal_button button {
  height: 48px;
  padding: 8px 36px;
  border-radius: 8px;
  text-align: center;
  /* TierOneAI/Button */
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  border: none;
}

.modal_button .yes {
  color: var(--TierOneAI-White, #fff);
  background: var(--TierOneAI-Error-Red, #e03838);
}

.modal_button .yes_success {
  color: var(--TierOneAI-White, #fff);
  background: #4c12a1;
  width: 180px;
}

.modal_button .no {
  color: var(--TierOneAI-Grey, #243030);
  border: 1px solid var(--Global-Grey, #243030);
  background: var(--TierOneAI-White, #fff);
  width: 180px;
}

.button_section button:nth-child(4) {
  background-color: #2aa87e;
  color: #fff;
  border: none;
}

.button_section button:nth-child(5) {
  background-color: #a1121e;
  color: #fff;
  border: none;
}

.resume_header {
  font-family: Lexend;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  text-align: left;
  margin: 0;
}

.resume_title {
  font-family: Lexend;
  font-size: 28px;
  font-weight: 300;
  line-height: 35px;
  letter-spacing: -0.0031em;
  text-align: left;
  margin: 0;
}

.resume_body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.resume_body_left {
  width: 60%;
}

.resume_body_right {
  width: 36%;
  overflow-y: scroll;
  height: 127vh;
}

.resume_body h3 {
  font-family: Lexend;
  font-size: 24px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: -0.0031em;
  text-align: left;
}
.resume_body ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.resume_body ul li h4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.resume_body ul li.resume_error h4 svg {
  color: #e03838;
  width: 16px;
  height: 16px;
}

.resume_body ul li.resume_warning h4 svg {
  color: #ffc757;
  width: 16px;
  height: 16px;
}

.resume_body ul li.resume_success h4 svg {
  color: #2aa87e;
  width: 16px;
  height: 16px;
}

.resume_body ul li.resume_warning span {
  display: block;
}

.resume_body ul li h4 {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  margin: 0;
  color: #0a1214;
}

.resume_body ul li p {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 300;
  line-height: 28px;
  text-align: left;
  color: #5b706f;
}

.resume_body ul li p span {
  font-weight: 500;
}

.modal_view {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(155, 151, 151, 0.2);
}

.dropdown_section {
  margin-bottom: 16px !important;
}

.modal_upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal_upload h2 {
  font-family: Lexend;
  font-size: 24px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: -0.0031em;
  text-align: center;
  margin: 0;
}

.modal_upload p {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 300;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
}

.ok_button {
  display: flex;
  height: 48px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #5b706f;

  color: #fff;
  border: none;
  cursor: pointer;
  width: 100%;
}

.tag_year {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: center;
  font-family: Lexend;
}

.tag_year span {
  background-color: #f3f8f8;
  padding: 2px 12px;
  border-radius: 48px;
  border: 1px solid #dae8e7;
  color: #5b706f;
  font-size: 12px;
  font-weight: 300;
}

.upload_section {
  width: 100%;
  height: 80vh;
}

.mid_section {
  margin-top: 15%;
}
.upload_button {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
}
.upload_button button {
  width: Hug (227px) px;
  height: 48px;
  padding: 8px 24px 8px 24px;
  gap: 8px;
  border-radius: 8px;
  background: #4c12a1;
  color: #fff;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
}

.upload_button button:disabled {
  background-color: #dae8e7;
  border: none;
  cursor: not-allowed;
  color: var(--global-body-grey, #bad3d1);
}

.upload_button svg {
  width: 16px;
  height: 16px;
}

.upload_bottom {
  display: flex;
  width: Fill (1, 248px) px;
  height: 8px;
  padding: 16px 8px 16px 8px;
  gap: 8px;

  align-items: center;
  justify-content: center;
  background: #f3f8f8;
  margin: 2px;
}

.start {
  display: flex;
  height: 48px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--NFTE-NFTE-Purple, #4c12a1);
  color: #fff;
  border: none;
  cursor: pointer;
  width: 100%;
}

.start:disabled {
  background: var(--global-light-grey, #f3f8f8);
  color: var(--global-body-grey, #5b706f);
  cursor: not-allowed;
}

.resume_body_left .react-pdf__Page__textContent {
  display: none;
}

.pdf_nav {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
  margin-top: 16px;
  align-items: center;
}

.pdf_nav p {
  font-family: Lexend;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
  color: #5b706f;
  margin: 0;
}

.pdf_nav button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: #5b706f;
}

.pdf_nav button:disabled {
  color: #dae8e7;
}

.pdf_nav button svg {
  width: 14px;
  height: 14px;
}

.pdf_page {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.pdf_page .react-pdf__Page__canvas {
  width: 100% !important;
}

.pdf_page_section {
  border: 1px solid #dae8e7;
  border-radius: 8px;
  box-shadow: 0px 16px 64px 0px rgba(10, 18, 20, 0.125);
}

.doc_section iframe {
  height: 120vh !important;
}
