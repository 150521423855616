.button,
.greenButton,
.whiteButton {
  display: flex;
  height: 52px;
  padding: 14px 63px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border-radius: 8px;
  background: var(--nftenfte-purple, #4c12a1);
  color: #fff;
  background-blend-mode: multiply;
  border: none;
  cursor: pointer;
  margin: 10px auto;
  font-size: 16px;
  font-weight: 500;
  font-family: Lexend;
  width: 265px;
}

.button:disabled {
  background-color: #dae8e7;
  border: none;
  cursor: not-allowed;
  color: var(--global-body-grey, #bad3d1);
}

.button svg,
.greenButton svg {
  width: 20px;
  height: 20px;
  fill: #fff;
}

.button:disabled svg {
  fill: var(--global-body-grey, #bad3d1);
}

.greenButton {
  background-color: var(--T1AI-Green, #2aa87e);
  color: #fff;
  padding: 8px 24px;
}

.unstyledButton {
  all: unset;
  color: #5b706f;

  cursor: pointer;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
}

.unstyledButton:hover {
  text-decoration: underline;
}

.whiteButton {
  border: 1px solid var(--Global-Grey, #243030);
  background: var(--TierOneAI-White, #fff);
  color: #243030;
}
