.container {
  display: flex;
  width: 1440px;
  height: 720px;
  padding: 32px 144px 64px 144px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: auto;
}

.loginform {
  display: flex;
  width: 448px;
  padding: 48px 24px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  border-radius: 8px;
  border: 1px solid var(--global-outline-grey, #dae8e7);
  background: var(--global-white, #fff);
  box-shadow: 0px 16px 64px 0px rgba(10, 18, 20, 0.13);
  margin: auto;
}

.loginform h3 {
  color: var(--global-grey, #243030);
  text-align: center;
  font-family: Lexend;
  font-size: 36px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
  letter-spacing: -0.225px;
  margin: 0;
}

.headline {
  color: var(--nftenfte-dark-blue, #055370);
  text-align: center;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  align-items: center;
}

.login_button {
  display: flex;

  height: 48px;

  width: 185px;
  padding: 8px 36px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 8px;
  background: var(--nftenfte-purple, #4c12a1);
  color: #fff;
  border: none;
  cursor: pointer;
}

.google {
  display: flex;
  width: 185px;
  height: 40px;
  padding-left: 0px;
  align-items: center;
  gap: 12px;
  border-radius: 2px;
  border: 1px solid #4285f4;
  border-radius: 0px 4px 4px 0px;
  background: #4285f4;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25),
    0px 0px 0px 0px rgba(0, 0, 0, 0.08);

  color: #fff;
  cursor: pointer;
}

.google img {
  width: 18px;
  height: 18px;
  padding: 10px;
  background-color: #fff;
  border-radius: 2px;
}

.form p {
  color: var(--global-body-grey, #5b706f);
  text-align: center;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
  margin: 0;
}

.policy {
  color: var(--global-form-grey, #8ca19f);
  text-align: center;
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
}
