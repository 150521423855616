.main {
  width: 100%;
  background: var(--global-light-grey, #f3f8f8);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  padding: 0 35px;
  width: 90%;
  background-color: var(--global-light-grey, #f3f8f8);
  max-width: 1600px;
  align-items: center;
  display: flex;
  flex-direction: column;

}

.content h1 {
  color: var(--Global-Grey, #243030);
  /* TierOneAI/H4 */
  font-family: Lexend;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%; /* 35px */
  letter-spacing: -0.087px;
}

.learner_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.learner_header h2 {
  color: var(--Global-Dark-Grey, #0a1214);
  font-family: Lexend;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
  letter-spacing: -0.075px;
}

.card {
  border-radius: 8px;
  border: 1px solid var(--T1AI-T1-Dark-Green, #00bf8c);
  background: var(--Global-White, #fff);

  box-shadow: 0px 16px 64px 0px rgba(10, 18, 20, 0.13),
    0px 0px 1px 0px #0a1214 inset;
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  align-self: stretch;
}

.header_buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

}

.comment {
  color: var(--TierOneAI-Body-Grey, #5b706f);
  text-align: center;

  curser: pointer;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 8px;
  border: 1px solid var(--TierOneAI-Body-Grey, #5b706f);
  display: flex;
  width: 176px;
  height: 48px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.back {
  display: flex;
  height: 48px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--NFTE-NFTE-Purple, #4c12a1);
  cursor: pointer;
  color: #fff;
  border: none;
}

.feedback {
  display: flex;
  height: 48px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--T1AI-Green, #2aa87e);
  color: var(--Global-White, #fff);
  text-align: center;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  cursor: pointer;
}

.complete {
  display: flex;
  height: 48px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--NFTE-NFTE-Purple, #2aa87e);
  color: var(--NFTE-NFTE-Purple, #2aa87e);
  text-align: center;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid var(--NFTE-NFTE-Purple, #2aa87e);
  background-color: #fff;
}

.header_buttons svg {
  width: 16px;
  height: 16px;
}

.border {
  border-bottom: 1px solid var(--T1AI-T1-Dark-Green, #dae8e7);

  margin-top: 20px;
  margin-bottom: 24px;
}

.input_fields {
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-bottom: 24px;
  margin-top: 24px;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.business_name {
  width: 550px;
}

.gradelist_status {
  width: auto;
}

.card {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Global-Outline-Grey, #dae8e7);
  background: var(--Global-White, #fff);
  width: 250px;
}

.card_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.card_header h5 {
  color: var(--Global-Grey, #243030);
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: -0.062px;
  margin: 0;
}

.card_header svg {
  width: 24px;
  height: 24px;
  color: #00bf8c;
  cursor: pointer;
}

.card_border {
  border-bottom: 1px solid var(--Global-Outline-Grey, #dae8e7);
  width: 100%;
}

.card_body p {
  color: var(--Global-Body-Grey, #5b706f);
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
  margin: 0;
}
.section_one {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  width: 100%;
}

.mid {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.section_two {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  width: 100%;
  margin-top: 24px;
  height: 100%;
}

.modal_header {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #0aa6e0;
}

.modal_header h5 {
  color: var(--Global-Grey, #243030);

  /* TierOneAI/H5 */
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%; /* 30px */
  letter-spacing: -0.075px;
  margin: 0;
  cursor: pointer;
}

.modal_header_card {
  border-bottom: 4px solid #0aa6e0;
}

.modal p {
  color: var(--Global-Body-Grey, #5b706f);

  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
  margin: 0;
}

.modal {
  display: flex;
  width: 640px;
  padding: 36px 24px;
  flex-direction: column;

  gap: 32px;
  flex-shrink: 0;
  border-radius: 8px;

  background: var(--Global-White, #fff);
  box-shadow: 0px 16px 64px 0px rgba(10, 18, 20, 0.25);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal_body h3 {
  color: var(--Global-Body-Grey, #5b706f);

  /* TierOneAI/PB */
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 175%; /* 28px */
}

.comment_field {
  width: 100%;
  margin-top: 34px;
  height: 100%;
}

.modal_body p {
  margin-bottom: 20px;
}
.ai_modal,
.edit_modal {
  display: flex;
  width: 60%;
  padding: 48px;
  flex-direction: column;

  gap: 32px;
  flex-shrink: 0;
  border-radius: 8px;

  background: var(--Global-White, #fff);
  box-shadow: 0px 16px 64px 0px rgba(10, 18, 20, 0.25);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}

.ai_card_body span {
  color: var(--TierOneAI-Form-Grey, #8ca19f);
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
}

.ai_card_body_bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.ai_card_body_bottom_buttons {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  color: #8ca19f;
  cursor: pointer;
}

.ai_card_body_bottom_buttons svg {
  width: 16px;
  height: 16px;
}
.ai_card_body p {
  color: var(--Global-Body-Grey, #5b706f);
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
}
.ai_card_body {
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;

  margin-top: 20px;

  border-radius: 8px;
  border: 1px solid var(--TierOneAI-Outline-Grey, #dae8e7);
  background: var(--Global-White, #fff);
}

.ai_card_body h6 {
  color: var(--Global-Grey, #243030);

  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%; /* 25px */
  letter-spacing: -0.062px;
  margin: 0;
}

.generate {
  display: flex;
  justify-content: center;
  margin-top: 50%;
  margin-bottom: 10px;
}
.generate button {
  display: flex;
  height: 48px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: var(--T1AI-Green, #2aa87e);
  text-align: center;
  border-radius: 8px;
  border: 2px solid var(--T1AI-Green, #2aa87e);
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 100%;
  cursor: pointer;
}

.generate svg {
  width: 16px;
  height: 16px;
}

.modal_title h5 {
  color: var(--TierOneAI-Grey, #243030);
  text-align: center;
  font-family: Lexend;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
  letter-spacing: -0.075px;
  margin: 0;
}

.modal_title p {
  color: var(--TierOneAI-Body-Grey, #5b706f);
  text-align: center;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
}
.modal_header_left {
  width: 50%;
}

.modal_view_header_left {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.modal_header_left p {
  color: var(--TierOneAI-Form-Grey, #8ca19f);
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
}
.remove {
  display: flex;
  height: 48px;
  padding: 8px 36px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  background: var(--TierOneAI-Error-Red, #e03838);
  color: var(--Global-White, #fff);
  cursor: pointer;
  border: none;
}

.cancel {
  height: 48px;
  padding: 8px 36px;
  border-radius: 8px;
  border: 1px solid var(--Global-Grey, #243030);
  background: var(--TierOneAI-White, #fff);

  cursor: pointer;
}

.modal_buttons {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-top: 30px;
}

.close {
  border-radius: 8px;
  border: 1px solid var(--Global-Body-Grey, #5b706f);
  background: var(--Global-White, #fff);
  display: flex;
  height: 48px;
  padding: 8px 36px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  width: 100%;
  cursor: pointer;
  color: var(--Global-Body-Grey, #5b706f);
  text-align: center;

  /* TierOneAI/Button */
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.modal_comment_section {
  max-height: 70vh;
  overflow-y: scroll;
}

.scorecard {
  border-radius: 8px;
  border: 1px solid var(--TierOneAI-Outline-Grey, #dae8e7);
  background: var(--Global-White, #fff);
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  flex: 1 1 23%;
  flex-direction: column;
}

.scorecard_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.scorecard_header span {
  color: var(--TierOneAI-Form-Grey, #8ca19f);

  /* TierOneAI/Nav Label */
  font-family: Lexend;
  font-size: 9px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%; /* 12px */
}

.scorecard_body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.scorecard_body h4 {
  color: var(--Global-Body-Grey, #5b706f);

  /* TierOneAI/PB */
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 175%;
  margin: 0;
}

.scorecard_bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.scorecard_bottom a {
  color: var(--TierOneAI-Body-Grey, #5b706f);
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  cursor: pointer;
}

.scorecard_section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 24px;
}

.ai_body {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.progress {
  width: 100%;
}
.top_left {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.top_left p {
  color: var(--NFTE-NFTE-Dark-Blue, #055370);
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 175%;
}

.top_left a {
  color: var(--TierOneAI-Body-Grey, #5b706f);
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  cursor: pointer;
}

.top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.top h4 {
  color: var(--NFTE-NFTE-Dark-Blue, #055370);
  text-align: right;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 175%;
}

.top_left a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.top_left a svg {
  width: 10px;
  height: 10px;
}

.scorecard_bottom a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.scorecard_bottom a svg {
  width: 10px;
  height: 10px;
}

.edit_modal_body {
  display: flex;
  flex-direction: column;

  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  align-self: stretch;
  margin-bottom: 24px;
}

.edit_modal_body_section {
  border-radius: 8px;
  padding: 24px;
  overflow: scroll;
  border: 1px solid var(--TierOneAI-Body-Grey, #5b706f);
}

.edit_textfield {
  width: 100%;
}

.save {
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 2px solid var(--T1AI-Green, #2aa87e);
  color: var(--T1AI-Green, #2aa87e);
  text-align: center;

  /* TierOneAI/Button */
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.ai_modal_top {
  display: flex;
  flex-direction: row;

  align-items: flex-end;
  gap: 16px;
}

.return {
  color: var(--TierOneAI-Body-Grey, #5b706f);
  text-align: center;

  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 2px solid var(--TierOneAI-Body-Grey, #5b706f);
  cursor: pointer;
}

.score {
  color: var(--TierOneAI-Body-Grey, #5b706f);
  text-align: right;

  /* TierOneAI/Element */
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%; /* 24.5px */

  width: 20px;
  height: 20px;
  padding: 0px 8px;

  align-items: center;
  gap: 8px;
}

.progress {
  margin-bottom: 10px;
}

.modal_body_text {
  max-height: 150px;
  overflow-y: scroll;
}

.modal_comment_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.modal_comment_header p {
  color: var(--TierOneAI-Dark-Grey, #0a1214);
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
  letter-spacing: -0.062px;
}

.modal_comment_header span {
  color: var(--TierOneAI-Body-Grey, #5b706f);
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.modal_border {
  border: 1px solid var(--Global-Outline-Grey, #dae8e7);
}

.post_body_head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.post_body_head_left,
.post_body_image,
.role {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  gap: 12px;
  justify-content: center;
}

.post_body_head img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.profile h4 {
  color: var(--global-grey, #243030);

  margin: 0;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 175%;
}
.profile p {
  color: var(--global-body-grey, #5b706f);

  /* TierOneAI/Fine Print */
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%; /* 18px */
  margin: 0;
}

.post_body_head_left span {
  border-radius: 48px;
  border: 1px solid var(--global-grey, #243030);
  opacity: 0.8;
  background: var(--global-grey, #243030);
  display: flex;
  padding: 2px 12px;
  align-items: center;
  gap: 4px;

  color: var(--global-white, #fff);
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.post_date h4 {
  color: var(--global-body-grey, #5b706f);
  text-align: right;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
  margin: 0;
}

.post_date p {
  color: var(--global-body-grey, #5b706f);
  text-align: right;
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  margin: 0;
}

.modal_comment_body p {
  color: var(--TierOneAI-Body-Grey, #5b706f);
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
}

.modal_bottom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  gap: 12px;
}

.reply {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
  background: #2aa87e;
  color: var(--Global-White, #fff);
  cursor: pointer;
  border: none;
  width: 100%;
}

.collapse {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
  background: var(--TierOneAI-Light-Grey, #f3f8f8);
  color: #2aa87e;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  border: none;
  width: 100%;
}

.generate svg {
  width: 16px;
  height: 16px;
}

.reply_field {
  width: 100%;
}

.drop_down svg {
  position: absolute;
  right: 0;
  left: 10;
  color: #8a8484;
  top: 9px;
  font-size: 25px;
  margin-right: 8px;
}

.filter_menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 2px solid #5b706f;
  border-radius: 8px;
}

.select {
  appearance: none;
  border: 0;
  outline: 0;
  font: inherit;
  padding: 8px 4rem 8px 1rem;
  background: #fff;
  color: #5b706f;
  border-radius: 8px;
  cursor: pointer;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
}

.activity_log_section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.activity_log_section ul li .date {
  color: var(--TierOneAI-Form-Grey, #8ca19f);
  /* TierOneAI/Fine Print */
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%; /* 18px */
  margin: 0;
}

.activity_log_section ul li .activity {
  color: var(--TierOneAI-Body-Grey, #5b706f);

  /* TierOneAI/Element */
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%; /* 21px */
  margin: 0;
  margin-bottom: 8px;
}

.activity_log_section ul li .user {
  color: var(--TierOneAI-Body-Grey, #5b706f);

  /* TierOneAI/Element Bold */
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
}

.activity_log_section ul li .activity_log_border {
  content: '';
  display: block;
  width: 1px;
  height: 32px;
  background: var(--TierOneAI-Outline-Grey, #dae8e7);
  margin: 8px 0;
}

.activity_log_section ul li:last-child .activity_log_border {
  display: none;
}

.currentUserList ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 16px;
  gap: 16px;
}

.currentUserList ul li {
  display: flex;
  padding: 4px 16px;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  border: 1px solid var(--TierOneAI-Outline-Grey, #dae8e7);
  background: var(--TierOneAI-Light-Grey, #f3f8f8);
}

.currentUser {
  color: var(--TierOneAI-Body-Grey, #5b706f);

  /* TierOneAI/Element */
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%; /* 21px */
  margin: 0;
}

.currentUserList ul li svg {
  color: #5b706f;
  font-size: 16px;
  cursor: pointer;
}

.inviteCollaborator {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 16px;
}

.autocomplete {
  position: relative;
  display: inline-block;
  width: 100%;
}

.inviteCollaborator .inviteButton {
  color: var(--Global-White, #fff);
  text-align: center;

  /* TierOneAI/Button */
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  border-radius: 8px;
  background: var(--NFTE-NFTE-Purple, #4c12a1);
  padding: 14px 16px;
  width: 200px;
  border: none;
  cursor: pointer;
}

.modalInviteCollaborator h1 {
  color: var(--Global-Grey, #243030);

  /* TierOneAI/H6 */
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%; /* 25px */
  letter-spacing: -0.062px;
}

.modalInviteCollaborator > p {
  color: var(--Global-Body-Grey, #5b706f);

  /* TierOneAI/Element Bold */
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
}

.comments {
  display: flex;
  height: 48px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--TierOneAI-Body-Grey, #5b706f);
  cursor: pointer;
  color: var(--TierOneAI-Body-Grey, #5b706f);
  text-align: center;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.btn_green {
  display: flex;
  height: 48px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--TierOneAI-Body-Grey, #2aa87e);
  cursor: pointer;
  color: var(--TierOneAI-Body-Grey, #fff);
  text-align: center;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background-color: #2aa87e;
}

.comments svg {
  width: 16px;
  height: 16px;
}

.inviteCollaboratorHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.inviteCollaboratorHeader svg {
  cursor: pointer;
}

.wrapper {
  position: relative;
  width: 200px;
  height: 50px;
  border: 1px solid #dde4e4;
  border-radius: 5px;
}

section,
select {
  border: 1px solid #ccc;
  padding: 1ex;
}

/* Generic floating style */
.float {
  position: absolute;
  top: -1.8ex;
  left: 10px;
  padding: 0 10px;
  background: #f3f8f8;
  z-index: 1;
  font-size: 10px;
  font-weight: 300;
  color: rgb(140, 161, 159);
  font-family: Lexend;
}
.wrapper select {
  width: 100%;
  height: 100%;
  background-color: #f3f8f8;
  border: 1px solid #dde4e4;
  border-radius: 5px;
  color: #a3a6a6;
  font-size: 14px;
  font-weight: 400;
  font-family: Lexend;
}

.wrapper2 {
  position: relative;
  width: 510px;
  height: 50px;
  border: 1px solid #dde4e4;
  border-radius: 5px;
}

.wrapper2 input {
  width: 100%;
  height: 100%;
  background-color: #f3f8f8;
  border: 1px solid #dde4e4;
  border-radius: 5px;
  color: #a3a6a6;
  font-size: 14px;
  font-weight: 400;
  font-family: Lexend;
}

.custom_wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  border: 2px solid #9fbfb4;
  border-radius: 5px;
  padding: 10px 0;
}
.custom_wrapper_float {
  position: absolute;
  top: -1.8ex;
  padding: 0 10px;
  background: #fff;
  z-index: 1;
  font-size: 14px;
  font-weight: 400;
  color: #000;
  font-family: Lexend;
}

.custom_wrapper textarea {
  width: 95%;
  height: 75%;
  background-color: transparent;
  border-radius: 5px;
  color: #565a5a;
  font-size: 14px;
  font-weight: 400;
  font-family: Lexend;
  outline: none;
  resize: none;
  padding: 10px;
  border: none;
  position: relative;
}
.custom_wrapper textarea:focus {
  border: none;
  resize: none;
  outline: none;
}

.wrapper_container {
  width: 100%;
  height: 100%;
  position: relative;
}

.btn_group {
  display: flex;
  gap: 10px;
  align-items: center;
  position: absolute;
  right: 0;
  top: -15px;
  z-index: 2;
}

.btn_group button {
  background-color: #fff;
  border: none;
  outline: none;
  color: #42816c;
  font-size: 14px;
  font-weight: 400;
  font-family: Lexend;
  cursor: pointer;
}

.btn_group button:hover {
  color: rgb(41, 68, 59);
}

.body {
  height: 100%;
  width: 90%;
  background-color: #fff;
  padding: 30px;
  display: flex;
  flex-direction: column;

  gap: 50px;
}
.widget {
  width: 100%;
  height: 100%;
}
.widget_body {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.indicator {
  display: flex;
  align-items: center;
  gap: 20px;
  height: 100%;
}
.count {
  display: flex;
  align-items: center;
  outline: none;
  border: none;
}

.line {
  width: 250px;
  height: 6px;
}
.widget_header {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.count p {
  color: #42816c;
  font-size: 16px;
  font-weight: 500;
  font-family: Lexend;
}

.sizebox {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.problem_body {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 10px;
}
.sizebox2 {
  width: 500px;
  height: 80px;
}
.group {
  display: flex;
  flex-direction: column;
  gap: 60px;
  align-items: end;
  width: 100%;
}
.left_content {
  color: #313030;
  font-size: 16px;
  font-weight: 300;
  font-family: Lexend;
  max-width: 600px;
}
.left_content h3 {
  font-size: 16px;
  font-weight: 500;
}
.left_content ol {
  margin-left: -15px;
}
.left_content ol > li {
  margin-bottom: 10px;
}
.problem_content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 40px;
  align-items: start;
}
ya .revenue_content {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: start;
  width: 100%;
}

.revenue_stream_payslip {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.revenue_stream_payslip h3 {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  font-family: Lexend;
}
.slip_record {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
}
.record {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin-top: -10px;
}
.line2 {
  height: 1px;
  background-color: #d0e2e1;
  width: 100%;
}
.record p {
  color: #000;
  font-size: 16px;
  font-weight: 300;
  font-family: Lexend;
  white-space: nowrap;
}

.progress {
  margin-top: 12px;
  background-color: rgb(229, 233, 235);
  height: 5px;
  position: relative;
  width: 250px;
  border: 1px solid #d0e2e1;
  border-radius: 5px;
}
.progress_bar {
  background-size: 23em 0.25em;
  height: 100%;
  position: relative;
  background-color: #2aa87e;
}

.save_btn {
  outline: none;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  font-family: Lexend;
  color: #2aa87e;
  background-color: transparent;
  cursor: pointer;
}
.save_btn:hover {
  color: #1e7759;
}

.num {
  background-color: #fff;
  border: 2px solid #d0e2e1;
  border-radius: 2px;
  width: 50px;
  height: 30px;
  color: #42816c;
  font-size: 16px;
  font-weight: 500;
  font-family: Lexend;
}
.sizebox3 {
  width: 100%;
  height: 80px;
}

.edit_and_check {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50%;
  padding: 5px;
  background-color: transparent;
  outline: none;
  cursor: pointer;
}
.edit_and_check:hover {
  color: #1e7759;
}
