.query {
  display: flex;
  flex-direction: row;
  background: var(--global-light-grey, #f3f8f8);
  height: 100vh;
}

.query__container {
  display: flex;
  width: 90%;
  flex-direction: column;
  padding: 32px 0;
}

.query__container > div {
  padding: 0 32px;
}

.add_query {
  border-radius: 8px;
  background: var(--nftenfte-purple, #4c12a1);
  color: var(--global-white, #ffffff);
  text-align: center;
  font-family: Lexend;
  font-size: 16px;
  width: 144px;
  height: 48px;
  border: none;
}

.border {
  margin-top: 16px;
  border-bottom: 1px solid var(--global-outline-grey, #dae8e7);
}

.query__container__body {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
}

.query__container__body p {
  color: var(--global-body-grey, #5b706f);
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
}

.details {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 16px;
  padding: 36px;
  border: 1px solid var(--global-outline-grey, #dae8e7);
  background: var(--global-white, #ffffff);
}

.details h4 {
  color: var(--global-grey, #243030);
  font-family: Lexend;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
  letter-spacing: -0.075px;
  margin: 0;
}

.drop_down svg {
  position: absolute;
  right: 0;
  left: 10;
  color: #8a8484;
  top: 10px;
  font-size: 25px;
  margin-right: 8px;
}

.back {
  display: flex;
  height: 48px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid var(--global-body-grey, #5b706f);
  width: 222px;
  cursor: pointer;
}

.back svg {
  width: 14px;
  height: 14px;
}

.logout {
  color: var(--nftenfte-violet, #32229c);
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 175%;
  cursor: pointer;
}

.logout_section {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin-top: 32px;
  cursor: pointer;
}

.logout_section svg {
  color: var(--nftenfte-violet, #32229c);
  font-size: 20px;
}

.details__form {
  display: flex;
  flex-wrap: wrap;
}

.details__form > div {
  width: calc(25% - 16px);
  margin: 8px;
}

.details__form > div:last-child {
  width: calc(100% - (75% + 48px));
  flex-grow: 1;
  min-width: 200px;
}

.query__container__header h4 {
  color: var(--global-grey, #243030);
  font-family: Lexend;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
  letter-spacing: -0.087px;
  margin: 0;
}

.password_change_form {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}

.password_change_form > div {
  width: 23%;
}

.submit_button {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  padding: 12px 16px;
  border-radius: 4px;
  background: var(--nftenfte-purple, #4c12a1);
  color: var(--global-white, #ffffff);
  border: none;
  cursor: pointer;
}

.submit_button:disabled {
  background: var(--global-light-grey, #f3f8f8);
  cursor: not-allowed;
}

.details p.alert_text {
  margin: 0;
  font-family: Lexend;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #e03838;
  display: flex;
  align-items: center;
  gap: 4px;
}

.details p.alert_text svg {
  font-size: 16px;
}
@media (max-width: 425px) {
  .query__container {
    width: 100%;
  }
  .query__container__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px;
  }
  .back {
    width: 80px;
  }

  .details input {
    width: 100%;
  }

  .details__form > div {
    width: 100%;
  }
}

.submit_button {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  padding: 12px 16px;
  border-radius: 4px;
  background: var(--nftenfte-purple, #4c12a1);
  color: var(--global-white, #ffffff);
  border: none;
  cursor: pointer;
}
