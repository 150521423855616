/* Floating Animation for the Icon */
@keyframes floating {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
.floating-icon {
  animation: floating 3s ease-in-out infinite;
}
