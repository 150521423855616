.main {
  width: 100%;
  background: var(--global-light-grey, #f3f8f8);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  padding: 0 35px;
  width: 95%;
  background-color: var(--global-light-grey, #f3f8f8);
  max-width: 1600px;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.content h1 {
  color: var(--Global-Grey, #243030);
  font-family: Lexend;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: -0.087px;
}

.border {
  border: 1px solid var(--global-outline-grey, #dae8e7);

  margin-top: 10px;
  margin-bottom: 30px;
}

.nav_btn {
  border: 1px solid #e03838;
  padding: 0 15px;
  border-radius: 5px;
  width: fit-content;
  display: flex;
  gap: 8px;
  align-items: center;
  background: none;
  outline: none;
  height: 43px;
  color: var(--Global-Grey, #e03838);
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  text-wrap: nowrap;
  text-decoration: none;
}

.nav_btn:hover {
  background-color: #9b1a1a;
  color: #fff;
}
.navbar_container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.navbar {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  height: 60px;
  justify-content: space-between;
}

@media (max-width: 425px) {
  .content {
    padding: 0 16px;
  }
  .learner_header h2 {
    font-size: 17px;
  }
  .learner_header {
    flex-direction: column;
    gap: 16px;
  }
  .search {
    width: 90%;
  }
  .learner_header_buttons {
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }

  .coach_roaster {
    padding: 20px;
  }

  .learner_table thead th {
    font-size: 10px;
  }

  .modal_view {
    width: 80%;
  }
}

.container {
  margin: 20px 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  gap: 40px;
  box-sizing: border-box;
}

/* page header ends here */

.header_action_bar {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 15px;
}

.check_button_group {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #fafdfd;
  border: 1px solid #dae8e7;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  padding: 0 10px;
  width: 'fit-content';
}

.horizontalbar_group {
  width: 100%;
  display: flex;
  gap: 20px;
  padding: 5px;
}
.horizontalbar_container {
  width: 97%;
  display: flex;
  gap: 10px;
  flex-direction: column;
  background-color: #fff;
  padding: 20px;
  border: 1px solid #dae8e7;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
.horizontalbar_container h1 {
  color: #243030;
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
}
.horizontalbar_container span {
  color: #5b706f;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
}

.widget_group {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 32px;
  padding: 0 20px;
  box-sizing: border-box;
  margin: 0 auto;
}

/* Large screens (1920px and below) */
@media (max-width: 1920px) {
  .widget_group {
    gap: 24px;
  }
}

/* Medium-large screens */
@media (max-width: 1280px) {
  .widget_group {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 24px;
  }
}

/* Tablets and smaller laptops */
@media (max-width: 1024px) {
  .widget_group {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 20px;
  }
}

/* Mobile devices */
@media (max-width: 767px) {
  .widget_group {
    grid-template-columns: 1fr;
    gap: 16px;
    padding: 0 10px;
  }

  .container {
    padding: 12px;
  }
}
