.main {
  display: flex;
  flex-direction: row;
  background: var(--global-light-grey, #f3f8f8);
}
.content {
  height: 100%;
  min-height: 100vh;
  padding: 0 35px;
  width: 90%;
}

.content h1 {
  color: var(--Global-Grey, #243030);

  /* TierOneAI/H4 */
  font-family: Lexend;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%; /* 35px */
  letter-spacing: -0.087px;
}

.border {
  border: 1px solid var(--global-outline-grey, #dae8e7);
  border-radius: 8px;

  margin-top: 15px;
}

.all {
  display: flex;
  flex-direction: row;

  align-items: center;
  padding: 36px;
  gap: 16px;
}

.container {
  padding: 36px;
  border-radius: 8px;
  border: 1px solid var(--Global-Outline-Grey, #dae8e7);
  background: var(--Global-White, #fff);
  height: 100%;
  width: 100%;
}

.container p {
  color: var(--Global-Body-Grey, #5b706f);

  /* TierOneAI/P */
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%; /* 28px */
}
.container_head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.container_head h6 {
  color: var(--Global-Grey, #243030);

  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%; /* 25px */
  letter-spacing: -0.062px;
  margin: 0;
}

.iteration {
  display: flex;
  padding: 16px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Global-Body-Grey, #5b706f);
  color: var(--Global-Body-Grey, #5b706f);
  text-align: center;
  background: #fff;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.save {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Global-Body-Grey, #2aa87e);
  color: var(--Global-Body-Grey, #2aa87e);
  text-align: center;
  background: none;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.button_group {
  display: flex;
  gap: 16px;
}

.input {
  width: 32%;
}

.container_body_input {
  display: flex;
  flex-direction: column;
  gap: 26px;
  margin-top: 30px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.comments {
  display: flex;
  width: 176px;
  height: 48px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--TierOneAI-Body-Grey, #5b706f);
  cursor: pointer;
  color: var(--TierOneAI-Body-Grey, #5b706f);
  text-align: center;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.comments svg {
  width: 16px;
  height: 16px;
}

.reply_field {
  width: 100%;
}
.next {
  display: flex;
  width: 176px;
  height: 48px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 8px;
  background: var(--T1AI-Green, #2aa87e);
  color: var(--Global-White, #fff);
  cursor: pointer;
  border: none;
  color: var(--Global-White, #fff);
  text-align: center;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.next svg {
  width: 18px;
  height: 18px;
}

.next:disabled {
  background: var(--TierOneAI-Disabled-Grey, #dae8e7);
  color: var(--TierOneAI-Disabled-White, #f3f8f8);
  cursor: not-allowed;
}

.header_buttons {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}

.add a {
  color: var(--NFTE-NFTE-Dark-Blue, #055370);
  text-align: center;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.add svg {
  width: 16px;
  height: 16px;
}
.add {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;

  cursor: pointer;
}

.card {
  display: flex;
  width: 288px;
  padding: 24px;
  flex-direction: column;
  gap: 16px;

  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Global-Outline-Grey, #dae8e7);
  background: var(--Global-Light-Grey, #f3f8f8);
}

.card_body {
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;

  gap: 8px;

  border-radius: 8px;
  border: 1px solid var(--TierOneAI-Outline-Grey, #dae8e7);
  background: var(--Global-White, #fff);
}

.card h6 {
  color: var(--Global-Grey, #243030);

  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%; /* 25px */
  letter-spacing: -0.062px;
  margin: 0;
}

.course_list ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid var(--NFTE-NFTE-Blue, #0aa6e0);
  margin-top: 24px;
}

.course_list li {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  cursor: pointer;
}

.course_list li.active {
  border-bottom: 4px solid var(--NFTE-NFTE-Blue, #0aa6e0);
}

.course_list li p {
  color: var(--Global-Grey, #243030);
  /* TierOneAI/Fine Print Bold */
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
}

.card_body p {
  color: var(--Global-Body-Grey, #5b706f);
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
}

.card_body span {
  color: var(--TierOneAI-Form-Grey, #8ca19f);
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
}

.card_body_bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.card_body_bottom_buttons {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  color: #8ca19f;
  cursor: pointer;
}

.card_body_bottom_buttons svg {
  width: 16px;
  height: 16px;
}

.modal_title h5 {
  color: var(--TierOneAI-Grey, #243030);
  text-align: center;
  font-family: Lexend;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
  letter-spacing: -0.075px;
  margin: 0;
}

.modal_title p {
  color: var(--TierOneAI-Body-Grey, #5b706f);
  text-align: center;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
}

.remove {
  display: flex;
  height: 48px;
  padding: 8px 36px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  background: var(--TierOneAI-Error-Red, #e03838);
  color: var(--Global-White, #fff);
  cursor: pointer;
  border: none;
}

.cancel {
  display: flex;
  height: 48px;
  padding: 8px 36px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid var(--Global-Grey, #243030);
  background: var(--TierOneAI-White, #fff);

  cursor: pointer;
}

.modal_buttons {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-top: 30px;
}

.post_content {
  max-height: 150px;
  overflow: hidden;
  max-height: all 0.5s cubic-bezier(0, 1, 0, 1);
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;

  gap: 8px;

  border-radius: 8px;
  border: 1px solid var(--TierOneAI-Outline-Grey, #dae8e7);
  background: var(--Global-White, #fff);
}

.post_content_expanded {
  max-height: 100%;
  overflow: hidden;
  transition: all 1s ease-in-out;
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;

  gap: 8px;

  border-radius: 8px;
  border: 1px solid var(--TierOneAI-Outline-Grey, #dae8e7);
  background: var(--Global-White, #fff);
}

.generate {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.generate button {
  display: flex;
  height: 48px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: var(--T1AI-Green, #2aa87e);
  text-align: center;
  border-radius: 8px;
  border: 2px solid var(--T1AI-Green, #2aa87e);
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 100%;
  cursor: pointer;
}

.modal_header {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #0aa6e0;
}

.modal_header_card {
  border-bottom: 4px solid #0aa6e0;
}

.modal_header p {
  color: var(--TierOneAI-Dark-Grey, #0a1214);
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
  letter-spacing: -0.062px;
}

.modal_header span {
  color: var(--TierOneAI-Body-Grey, #5b706f);
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.modal_border {
  border: 1px solid var(--Global-Outline-Grey, #dae8e7);
}

.post_body_head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.post_body_head_left,
.post_body_image,
.role {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  gap: 12px;
  justify-content: center;
}

.post_body_head img {
  width: 32px;
  height: 32px;
}

.profile h4 {
  color: var(--global-grey, #243030);

  margin: 0;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 175%;
}
.profile p {
  color: var(--global-body-grey, #5b706f);

  /* TierOneAI/Fine Print */
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%; /* 18px */
  margin: 0;
}

.post_body_head_left span {
  border-radius: 48px;
  border: 1px solid var(--global-grey, #243030);
  opacity: 0.8;
  background: var(--global-grey, #243030);
  display: flex;
  padding: 2px 12px;
  align-items: center;
  gap: 4px;

  color: var(--global-white, #fff);
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.post_date h4 {
  color: var(--global-body-grey, #5b706f);
  text-align: right;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
  margin: 0;
}

.post_date p {
  color: var(--global-body-grey, #5b706f);
  text-align: right;
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  margin: 0;
}

.modal_body p {
  color: var(--TierOneAI-Body-Grey, #5b706f);
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
}

.modal_bottom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  gap: 12px;
}

.reply {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
  background: #2aa87e;
  color: var(--Global-White, #fff);
  cursor: pointer;
  border: none;
  width: 100%;
}

.reply:disabled {
  background: var(--TierOneAI-Disabled-Grey, #dae8e7);
  color: var(--TierOneAI-Disabled-White, #f3f8f8);
  cursor: not-allowed;
}

.collapse {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
  background: var(--TierOneAI-Light-Grey, #f3f8f8);
  color: #2aa87e;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  border: none;
  width: 100%;
}

.generate svg {
  width: 16px;
  height: 16px;
}

.feedback {
  display: flex;
  height: 48px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--T1AI-Green, #2aa87e);
  color: var(--Global-White, #fff);
  text-align: center;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  cursor: pointer;
}

.modal_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.modal_header h5 {
  color: var(--Global-Grey, #243030);

  /* TierOneAI/H5 */
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%; /* 30px */
  letter-spacing: -0.075px;
  margin: 0;
  cursor: pointer;
}

.modal p {
  color: var(--Global-Body-Grey, #5b706f);

  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
  margin: 0;
}

.modal {
  display: flex;
  width: 640px;
  padding: 36px 24px;
  flex-direction: column;

  gap: 32px;
  flex-shrink: 0;
  border-radius: 8px;

  background: var(--Global-White, #fff);
  box-shadow: 0px 16px 64px 0px rgba(10, 18, 20, 0.25);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal_body h3 {
  color: var(--Global-Body-Grey, #5b706f);

  /* TierOneAI/PB */
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 175%; /* 28px */
}

.comment_field {
  width: 100%;
  margin-top: 34px;
  height: 100%;
}

.modal_body p {
  margin-bottom: 20px;
}
.ai_modal,
.edit_modal {
  display: flex;
  width: 60%;
  padding: 48px;
  flex-direction: column;

  gap: 32px;
  flex-shrink: 0;
  border-radius: 8px;

  background: var(--Global-White, #fff);
  box-shadow: 0px 16px 64px 0px rgba(10, 18, 20, 0.25);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}

.ai_card_body span {
  color: var(--TierOneAI-Form-Grey, #8ca19f);
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
}

.ai_card_body_bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.ai_card_body_bottom_buttons {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  color: #8ca19f;
  cursor: pointer;
}

.ai_card_body_bottom_buttons svg {
  width: 16px;
  height: 16px;
}
.ai_card_body p {
  color: var(--Global-Body-Grey, #5b706f);
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
}
.ai_card_body {
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;

  margin-top: 20px;

  border-radius: 8px;
  border: 1px solid var(--TierOneAI-Outline-Grey, #dae8e7);
  background: var(--Global-White, #fff);
}

.ai_card_body h6 {
  color: var(--Global-Grey, #243030);

  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%; /* 25px */
  letter-spacing: -0.062px;
  margin: 0;
}

.generate {
  display: flex;
  justify-content: center;
  margin-top: 50%;
  margin-bottom: 10px;
}
.generate button {
  display: flex;
  height: 48px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: var(--T1AI-Green, #2aa87e);
  text-align: center;
  border-radius: 8px;
  border: 2px solid var(--T1AI-Green, #2aa87e);
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 100%;
  cursor: pointer;
}

.generate svg {
  width: 16px;
  height: 16px;
}

.modal_title h5 {
  color: var(--TierOneAI-Grey, #243030);
  text-align: center;
  font-family: Lexend;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
  letter-spacing: -0.075px;
  margin: 0;
}

.modal_title p {
  color: var(--TierOneAI-Body-Grey, #5b706f);
  text-align: center;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
}

.modal_header_left p {
  color: var(--TierOneAI-Form-Grey, #8ca19f);
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
}
.remove {
  display: flex;
  height: 48px;
  padding: 8px 36px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  background: var(--TierOneAI-Error-Red, #e03838);
  color: var(--Global-White, #fff);
  cursor: pointer;
  border: none;
}

.cancel {
  display: flex;
  height: 48px;
  padding: 8px 36px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid var(--Global-Grey, #243030);
  background: var(--TierOneAI-White, #fff);

  cursor: pointer;
}

.modal_buttons {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-top: 30px;
}

.close {
  border-radius: 8px;
  border: 1px solid var(--Global-Body-Grey, #5b706f);
  background: var(--Global-White, #fff);
  display: flex;
  height: 48px;
  padding: 8px 36px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  width: 100%;
  cursor: pointer;
  color: var(--Global-Body-Grey, #5b706f);
  text-align: center;

  /* TierOneAI/Button */
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.scorecard {
  border-radius: 8px;
  border: 1px solid var(--TierOneAI-Outline-Grey, #dae8e7);
  background: var(--Global-White, #fff);
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  flex: 1 1 23%;
  flex-direction: column;
}

.scorecard_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.scorecard_header span {
  color: var(--TierOneAI-Form-Grey, #8ca19f);

  /* TierOneAI/Nav Label */
  font-family: Lexend;
  font-size: 9px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%; /* 12px */
}

.scorecard_body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.scorecard_body h4 {
  color: var(--Global-Body-Grey, #5b706f);

  /* TierOneAI/PB */
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 175%;
  margin: 0;
}

.scorecard_bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.scorecard_bottom a {
  color: var(--TierOneAI-Body-Grey, #5b706f);
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  cursor: pointer;
}

.scorecard_section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 24px;
}

.ai_body {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.progress {
  width: 100%;
}
.top_left {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.top_left p {
  color: var(--NFTE-NFTE-Dark-Blue, #055370);
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 175%;
}

.top_left a {
  color: var(--TierOneAI-Body-Grey, #5b706f);
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  cursor: pointer;
}

.top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.top h4 {
  color: var(--NFTE-NFTE-Dark-Blue, #055370);
  text-align: right;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 175%;
}

.top_left a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.top_left a svg {
  width: 10px;
  height: 10px;
}

.scorecard_bottom a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.scorecard_bottom a svg {
  width: 10px;
  height: 10px;
}

.edit_modal_body {
  display: flex;
  flex-direction: column;

  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  align-self: stretch;
  margin-bottom: 24px;
}

.edit_modal_body_section {
  border-radius: 8px;
  padding: 24px;
  overflow: scroll;
  border: 1px solid var(--TierOneAI-Body-Grey, #5b706f);
}

.edit_textfield {
  width: 100%;
}

.save {
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 2px solid var(--T1AI-Green, #2aa87e);
  color: var(--T1AI-Green, #2aa87e);
  text-align: center;

  /* TierOneAI/Button */
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.ai_modal_top {
  display: flex;
  flex-direction: row;

  align-items: center;
  gap: 16px;
}

.return {
  color: var(--TierOneAI-Body-Grey, #5b706f);
  text-align: center;

  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 2px solid var(--TierOneAI-Body-Grey, #5b706f);
  cursor: pointer;
}

.score {
  color: var(--TierOneAI-Body-Grey, #5b706f);
  text-align: right;

  /* TierOneAI/Element */
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%; /* 24.5px */

  width: 10px;
  height: 20px;
  padding: 0px 8px;

  align-items: center;
  gap: 8px;
}

.progress {
  margin-bottom: 10px;
}

.modal_body_text {
  max-height: 150px;
  overflow-y: scroll;
}

.modal_comment_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.modal_comment_header p {
  color: var(--TierOneAI-Dark-Grey, #0a1214);
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
  letter-spacing: -0.062px;
}

.modal_comment_header span {
  color: var(--TierOneAI-Body-Grey, #5b706f);
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.modal_border {
  border: 1px solid var(--Global-Outline-Grey, #dae8e7);
}

.post_body_head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.post_body_head_left,
.post_body_image,
.role {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  gap: 12px;
  justify-content: center;
}

.post_body_head img {
  width: 32px;
  height: 32px;
}

.profile h4 {
  color: var(--global-grey, #243030);

  margin: 0;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 175%;
}
.profile p {
  color: var(--global-body-grey, #5b706f);

  /* TierOneAI/Fine Print */
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%; /* 18px */
  margin: 0;
}

.post_body_head_left span {
  border-radius: 48px;
  border: 1px solid var(--global-grey, #243030);
  opacity: 0.8;
  background: var(--global-grey, #243030);
  display: flex;
  padding: 2px 12px;
  align-items: center;
  gap: 4px;

  color: var(--global-white, #fff);
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.post_date h4 {
  color: var(--global-body-grey, #5b706f);
  text-align: right;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
  margin: 0;
}

.post_date p {
  color: var(--global-body-grey, #5b706f);
  text-align: right;
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  margin: 0;
}

.modal_comment_body p {
  color: var(--TierOneAI-Body-Grey, #5b706f);
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
}

.modal_bottom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  gap: 12px;
}

.reply {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
  background: #2aa87e;
  color: var(--Global-White, #fff);
  cursor: pointer;
  border: none;
  width: 100%;
}

.collapse {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
  background: var(--TierOneAI-Light-Grey, #f3f8f8);
  color: #2aa87e;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  border: none;
  width: 100%;
}

.drop_down svg {
  position: absolute;
  right: 0;
  left: 10;
  color: #8a8484;
  top: 9px;
  font-size: 25px;
  margin-right: 8px;
}

.filter_menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 2px solid #5b706f;
  border-radius: 8px;
}

.select {
  appearance: none;
  border: 0;
  outline: 0;
  font: inherit;
  padding: 8px 4rem 8px 1rem;
  background: #fff;
  color: #5b706f;
  border-radius: 8px;
  cursor: pointer;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
}

.modal_header_left {
  width: 50%;
}

.activity_log_section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.activity_log_section ul li .date {
  color: var(--TierOneAI-Form-Grey, #8ca19f);
  /* TierOneAI/Fine Print */
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%; /* 18px */
  margin: 0;
}

.activity_log_section ul li .activity {
  color: var(--TierOneAI-Body-Grey, #5b706f);

  /* TierOneAI/Element */
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%; /* 21px */
  margin: 0;
  margin-bottom: 8px;
}

.activity_log_section ul li .user {
  color: var(--TierOneAI-Body-Grey, #5b706f);

  /* TierOneAI/Element Bold */
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
}

.activity_log_section ul li .activity_log_border {
  content: '';
  display: block;
  width: 1px;
  height: 32px;
  background: var(--TierOneAI-Outline-Grey, #dae8e7);
  margin: 8px 0;
}

.activity_log_section ul li:last-child .activity_log_border {
  display: none;
}

.body {
  padding: 40px 0px;
  display: flex;
  flex-direction: row;
  gap: 24px;
  flex-wrap: wrap;
}

.input_tab {
  width: 100%;
  gap: 24px;
  display: flex;
  flex-direction: column;
}

.add {
  color: var(--NFTE-NFTE-Dark-Blue, #055370);
}
.add p {
  color: var(--NFTE-NFTE-Dark-Blue, #055370);
  text-align: center;

  /* TierOneAI/Element Bold */
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
}

.iteration svg {
  width: 16px;
  height: 16px;
}

.college_section,
.curricular_section {
  display: flex;
  width: 100%;
  padding: 12px;
  flex-direction: column;

  gap: 24px;
  border-radius: 8px;
  border: 1px solid var(--TierOneAI-Outline-Grey, #dae8e7);
  background: var(--TierOneAI-Pale-Grey, #fafdfd);
}

.curricular_section {
  margin-top: 16px;
}

.college_section p {
  color: var(--TierOneAI-Body-Grey, #5b706f);

  margin: 0;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 175%; /* 28px */
}

.programs {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 24px;
}

.programs .autocomplete {
  width: 100%;
}
.add_section {
  color: #055370;
}

.top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.delete {
  background: none;
  border: none;
  cursor: pointer;
  font-family: lexend;
  color: #5b706f;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 5px;
}

.delete svg {
  width: 16px;
  height: 16px;
}

.remove_modal {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
  padding: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background: var(--Global-White, #fff);

  border-radius: 8px;

  box-shadow: 0px 16px 64px 0px rgba(10, 18, 20, 0.25);
}

.remove_modal h2 {
  margin: 0;
}

.remove_modal p {
  color: var(--TierOneAI-Body-Grey, #5b706f);
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
  margin: 0;
}
.remove_modal_buttons {
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
}

.cancel {
  display: flex;
  height: 48px;
  padding: 8px 36px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid var(--Global-Grey, #243030);
  background: var(--TierOneAI-White, #fff);
  color: #0a1214;
  cursor: pointer;
}
