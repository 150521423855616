.main {
  width: 100%;
  background: var(--global-light-grey, #f3f8f8);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  padding: 0 35px;
  width: 95%;
  background-color: var(--global-light-grey, #f3f8f8);
  max-width: 1600px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content h1 {
  color: var(--Global-Grey, #243030);
  font-family: Lexend;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: -0.087px;
}
.container {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  width: 100%;
  background-color: #fff;
  flex-direction: column;
  align-items: start;
  height: 100%;
  padding: 20px;
  gap: 40px;
}
.border {
  border: 1px solid var(--global-outline-grey, #dae8e7);

  margin-top: 10px;
  margin-bottom: 30px;
}
.navbar {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  height: 60px;
  justify-content: space-between;
}
.nav_right {
  display: flex;
  align-items: center;
  gap: 10px;
}
.nav_btn1 {
  border: 1px solid #bbbfbf;
  padding: 0 15px;
  border-radius: 5px;
  width: fit-content;
  display: flex;
  gap: 8px;
  align-items: center;
}

.nav_btn2 {
  border: 1px solid #00706c;
  padding: 0 15px;
  border-radius: 5px;
  width: fit-content;
  display: flex;
  gap: 8px;
  align-items: center;
  background: none;
  outline: none;
  height: 43px;
  color: var(--Global-Grey, #00706c);
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  text-wrap: nowrap;
  text-decoration: none;
}

.nav_btn2:hover {
  background-color: #2aa87e;
  color: #fff;
}
.navbar_container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
@media (max-width: 425px) {
  .content {
    padding: 0 16px;
  }
  .learner_header h2 {
    font-size: 17px;
  }
  .learner_header {
    flex-direction: column;
    gap: 16px;
  }
  .search {
    width: 90%;
  }
  .learner_header_buttons {
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }

  .coach_roaster {
    padding: 20px;
  }

  .learner_table thead th {
    font-size: 10px;
  }

  .modal_view {
    width: 80%;
  }
}

/* more css start from there */
.container_split {
  display: flex;
  gap: 5px;
  align-items: stretch; /* Ensure both containers stretch to the same height */
  width: 100%;
  height: 100%;
}

.container_left {
  flex: 1; /* Take up equal space, or adjust for different ratios */
  display: flex;
  flex-direction: column;
  align-items: center; /* 'start' doesn't exist, 'flex-start' is the correct value */
  padding: 0 30px;
  gap: 20px;
  position: relative;
  width: 100%;
  max-height: 70vh;
  min-height: 70vh;
}

.container_right {
  flex: 1; /* Take up equal space, or adjust for different ratios */
  position: relative;
  border-radius: 8px;
  padding: 0 30px;
  max-height: 70vh;
  min-height: 70vh;
}

.ongoing_call_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 10px;
  border: 1px solid #dae8e7;
  border-radius: 5px;
  width: 100%;
  height: 95%;
  justify-content: space-between;
}
.ai_chat_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 10px;
  border: 1px solid #dae8e7;
  border-radius: 5px;
  width: 100%;
  height: 95%;
  overflow-y: auto;
  overflow-x: hidden;
}
.ongoing_call_container h1 {
  color: #243030;
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  text-wrap: nowrap;
}

/* Call animation starts here */

.caller_container {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}
.end_call {
  width: 100%;
  background-color: #e03838;
  border: none;
  outline: none;
  height: 60px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
}
.end_call p {
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  text-wrap: nowrap;
  line-height: 0;
}
.call_end_icon {
  display: flex;
  align-items: center;
  gap: 10px;
}
.end_call:hover {
  background-color: #ac1515;
}

.end_call:disabled {
  background-color: #b2b2b2;
  cursor: not-allowed;
}

/* General message container styling */
.message_container {
  display: flex;
  gap: 5px; /* Gap between avatar and message content */
  align-items: flex-start; /* Align items at the top */
  width: 98%;
  padding: 5px 10px;
}

/* Message content (message text and time) */
.message_content {
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 70%; /* Make sure the message doesn't take full width */
}

.message_content span {
  color: #5b706f;
  font-family: Lexend;
  font-size: 8px;
  font-weight: 300;
}

.message_content p {
  font-family: Lexend;
  font-size: 14px;
  font-weight: 300;
  word-break: break-word; /* Ensure text breaks properly in long messages */
}

/* Sender message: Align to the left, avatar on the left */
.sender_message {
  justify-content: flex-start; /* Content aligned to the left */
}
.sender_message .sender {
  border: 1px solid #dae8e7;
  padding: 1px 10px;
  border-radius: 5px;
  background-color: #f3f8f8;
}
.sender_message img {
  order: 0; /* Avatar stays on the left */
}

.sender_message .message_content {
  order: 1; /* Message content stays on the right */
  width: 100%;
}

.sender_message p {
  background-color: #f3f8f8;
  color: #5b706f;
}

/* Receiver message: Align to the right, avatar on the right */
.receiver_message {
  justify-content: flex-end; /* Content aligned to the right */
}

.receiver_message img {
  order: 1; /* Avatar on the right */
}

.receiver_message .message_content {
  order: 0; /* Message content on the left */
  width: 100%;
}

.receiver_message .sender {
  background-color: #5b706f;
  color: white;
  border: 1px solid #dae8e7;
  padding: 1px 10px;
  border-radius: 5px;
}

/* Avatar styling */
.message_container img {
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: 50%;
  margin-top: 5px;
}

/* Optional styling for the time */
.sender_message span {
  align-self: flex-start;
}
.receiver_message span {
  align-self: flex-end;
}
.sender {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;
}
.progress_container {
  width: 100%;
  background-color: #f3f8f8;
  border-radius: 10px;
  padding: 1px;
  height: 5px;
  margin-top: 5px;
  overflow: hidden; /* To keep the progress bar rounded */
}

/* Progress bar */
.progress_bar {
  height: 100%;
  background-color: green;
  border-radius: 10px 0 0 10px; /* Rounded edge */
  transition: width 0.3s ease; /* Smooth transition */
}
.response_score {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 10px;
}
.response_score p {
  text-wrap: nowrap;
}

.ai_suggested_ans {
  border: 1px solid #dae8e7;
  padding: 10px 10px;
  border-radius: 5px;
  background-color: #f3f8f8;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: start;
  width: 95%;
}
.ai_suggested_btn {
  display: flex;
  gap: 10px;
  background-color: #f3f8f8;
  border: 2px solid #2aa87e;
  border-radius: 5px;
  padding: 1px 8px;
  text-wrap: nowrap;
  align-items: center;
  width: fit-content;
  color: #243030;
}
.ai_suggested_btn h4 {
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  text-wrap: nowrap;
  line-height: 0;
}

.ai_suggested_ans p {
  font-family: Lexend;
  font-size: 14px;
  font-weight: 300;
  word-break: break-word;
  color: #243030;
}
.subtitle {
  width: 95%;
  position: relative;
}
.subtitle_btn_group {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 10px;
  position: relative;
}

/* typewriter style starts here */
.typewriter {
  width: 100%;
  font-family: Lexend;
  font-size: 14px;
  font-weight: 300;
  color: #525050;
  text-align: center;
}
.typewriter p {
  width: 100%;
  text-align: center;
  word-wrap: break-word;
  overflow-x: hidden;
  overflow-y: scroll;
  text-align: justify;
}

/* typewriter style ends here */

/* skeleton loader */

.chat {
  display: flex;
  overflow: hidden;
  background: white;
  width: 100%;
  border: none;
  margin-bottom: 10px; /* Optional: Space between chat messages */
}

.chatContent {
  width: calc(100% - 60px);
  display: flex; /* Add flex display to chatContent */
  flex-direction: column; /* Align items in a column */
}

.alignStart {
  align-items: flex-start; /* Align text to the start (left) */
}

.alignEnd {
  align-items: flex-end; /* Align text to the end (right) */
}

.chatAvatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0;
  margin-right: 10px;
  margin-left: 10px;
  padding: 0;
  overflow: hidden;
}

.chatTextSmall {
  width: 200px;
  height: 40px;
  border-radius: 5px;
}

.chatTextLarge {
  width: 100%;
  height: 74px;
  margin-top: 5px;
  border-radius: 5px;
}

.loading {
  position: relative;
  background-color: #e2e2e2;
}

.loading::after {
  display: block;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.6),
    transparent
  );
  animation: loading 1.5s infinite;
}

/* New styles for alignment */
.alignLeft {
  flex-direction: row; /* Standard layout for left alignment */
}

.alignRight {
  flex-direction: row-reverse; /* Flips the order of flex items for right alignment */
}

@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}
