.all {
  display: flex;
  flex-direction: row;

  background: var(--global-light-grey, #f3f8f8);
}

.main {
  display: flex;
  width: 90%;
  flex-direction: column;
  padding: 32px;
  box-sizing: border-box;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 15px !important;
}

.header h1 {
  color: var(--global-grey, #243030);
  font-family: Lexend;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
  letter-spacing: -0.087px;
}
.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.line {
  stroke-width: 1px;
  stroke: var(--global-outline-grey, #dae8e7);
  border: 1px solid var(--global-outline-grey, #dae8e7);
  width: 100%;
}
.return {
  display: flex;
  height: 48px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--global-body-grey, #5b706f);
  color: var(--global-body-grey, #5b706f);
  text-align: center;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.return svg {
  width: 16px;
  height: 16px;
}
.save {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--nftenfte-purple, #4c12a1);
  color: var(--global-white, #fff);
  text-align: center;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  cursor: pointer;
}

.save svg {
  width: 16px;
  height: 16px;
}
.invite_section,
.bulk_modal_invite_section {
  display: flex;

  padding: 36px;
  flex-direction: column;
  align-items: flex-start;
  /* gap: 32px; */
  border-radius: 8px;
  border: 1px solid var(--global-outline-grey, #dae8e7);
  background: var(--global-white, #fff);

  margin-top: 24px;
}
.invite_section h5,
.bulk_modal_invite_section h5 {
  color: var(--global-grey, #243030);
  font-family: Lexend;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
  letter-spacing: -0.075px;
  margin: 10px;
}

.input_section {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}
.invite {
  display: flex;
  height: 52px;
  width: 33%;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border-radius: 8px;
  background: var(--nftenfte-purple, #4c12a1);
  color: #fff;
  background-blend-mode: multiply;
  border: none;
  cursor: pointer;
  margin: 10px auto;
}

.invite_disabled {
  display: flex;
  height: 52px;
  width: 33%;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border-radius: 8px;
  background: none;
  background-blend-mode: multiply;
  border: none;
  cursor: not-allowed;

  border: 1px solid var(--global-body-grey, #5b706f);
  color: var(--global-body-grey, #5b706f);
  margin: 20px auto 0;
}

.invite svg {
  width: 16px;
  height: 16px;
}
.cancel {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 48px;
  width: 220px;
  border-radius: 8px;
  background: var(--nftenfte-purple, #4c12a1);
  border: none;
  color: var(--global-white, #fff);
  cursor: pointer;
}

.cancel svg {
  width: 16px;
  height: 16px;
}

.coach_roaster {
  display: flex;

  padding: 36px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  border-radius: 8px;
  border: 1px solid var(--global-outline-grey, #dae8e7);
  background: var(--global-white, #fff);
  margin-top: 20px;
}

.coach_roaster h5 {
  color: var(--global-grey, #243030);
  font-family: Lexend;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
  letter-spacing: -0.075px;
  margin: 10px;
}
.coach_top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  width: 100%;
}

.coach_table {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  overflow-x: auto;
}
.coach_table thead th {
  border-bottom: 1px solid var(--global-outline-grey, #dae8e7);
  padding-bottom: 10px;
  text-align: center;
}
.coach_table thead th:first-child {
  text-align: left;
}

.coach_table thead th:last-child {
  text-align: right;
}

.coach_table table tr td {
  text-align: center;
}
.coach_table table tr td:first-child {
  text-align: left;
}

.coach_table table tr {
  padding-bottom: 20px;
}

.coach_table table tr.coachrow td {
  padding: 16px 0;
}

.top_right,
.student_top_right {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  gap: 16px;
  min-width: 60%;
}
.student_top_right {
  min-width: 75%;
}
.radio_buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  border-radius: 4px;
  background: var(--global-pale-grey, #fafdfd);
}

.student_top_right .radio_buttons {
  width: 35%;
}

.search {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.empty_table {
  width: 100%;
  border: 1px solid var(--global-outline-grey, #dae8e7);
  background: var(--global-light-grey, #f3f8f8);
  padding: 36px 0px;
}
.empty_table p {
  text-align: center;
}

.coach_table table tr th,
.coach_table table tr td {
  width: 21%;
}

.data {
  color: var(--global-body-grey, #5b706f);
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
}

.select {
  /* Reset */
  appearance: none;
  border: 0;
  outline: 0;
  font: inherit;
  /* Personalize */
  height: 3.5em;
  padding: 0 4em 0 1em;
  background: #fff;
  color: #000;
  border-radius: 0.25em;
  border: 1px solid #d3cccc;
  cursor: pointer;
  /* <option> colors */
  color: var(--global-body-grey, #5b706f);
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
}

.select:focus {
  outline: none;
  color: var(--global-body-grey, #5b706f);
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
}

.option {
  color: inherit;
  background-color: #fff;
  color: var(--global-body-grey, #5b706f);
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
}
.drop_down svg {
  position: absolute;
  right: 0;
  left: 10;
  color: #8a8484;
  top: 15px;
  font-size: 25px;
  margin-right: 8px;
}

.filter_menu {
  display: flex;
  flex-direction: row;
}

.status {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-end;
}

.status button {
  all: unset;
  color: var(--nftenfte-dark-blue, #055370);
  cursor: pointer;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
}

.status img {
  width: 16px;
  height: 16px;
}

.resend {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

.revoke {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

.onboarded_section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.center {
  text-align: center;
}

.custom_modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 448px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #dae8e7;
  padding: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  outline: none;
  gap: 16px;
}

.bulk_modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 85%;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #dae8e7;
  padding: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  outline: none;
  gap: 16px;
}

.modal_header {
  color: var(--TierOneAI-Grey, #243030);
  text-align: center;

  /* TierOneAI/H5 */
  font-family: Lexend;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%; /* 30px */
  letter-spacing: -0.075px;
  margin: 0;
}

.modal_description {
  color: var(--TierOneAI-Body-Grey, #5b706f);
  text-align: center;

  /* TierOneAI/P */
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%; /* 28px */
  margin: 0;
}

.modal_button,
.bulk_modal_button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  width: 70%;
}

.bulk_modal_button {
  justify-content: space-between;
}

.bulk_modal_group_button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.modal_button button {
  height: 48px;
  width: 50%;
  padding: 8px 36px;
  border-radius: 8px;
  text-align: center;
  /* TierOneAI/Button */
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.modal_button .yes {
  color: var(--TierOneAI-White, #fff);
  background: var(--TierOneAI-Error-Red, #e03838);
}

.modal_button .no {
  color: var(--TierOneAI-Grey, #243030);
  border: 1px solid var(--Global-Grey, #243030);
  background: var(--TierOneAI-White, #fff);
}

.form_menu {
  display: flex;
  flex-direction: row;

  align-items: flex-start;

  width: 100%;
}
.form_menu > div {
  width: 35%;
  margin: 8px;
}

.filter_menu {
  width: 66%;
  margin-left: 8px;
}

.drop_down,
.drop_down_select {
  position: relative;

  width: 49%;
}

.invite_section_header,
.invite_bulk_section_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 85%;
}

.bulk_invite_scroll {
  width: 85%;
  overflow-y: auto;
  max-height: 65vh;
}

.invite_bulk_section_header {
  width: 100%;
}

.invite_section_header p,
.invite_bulk_section_header p {
  font-size: 14px;
  font-weight: 500;
  font-family: Lexend;
}

.invite_section_header span,
.invite_bulk_section_header span {
  font-size: 14px;
  font-weight: 500;
  font-family: Lexend;
  color: #e03838;
}

.bulk_modal_header h3,
.invite_section_header h5 {
  font-size: 20px;
  font-weight: 300;
  font-family: Lexend;
  margin: 0;
}
.bulk_invite_section {
  width: 85%;
}

.invite_bulk_button_section {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.bulk_modal_invite_section {
  width: 100%;
}

.add_course {
  width: 100%;
}

.add_course_input {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
}

.add_course_input div p {
  margin: 0;
  font-size: 14px;
  color: red;
}

.add_course_input_section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

@media (max-width: 425px) {
  .invite_section {
    padding: 16px;
    width: 100%;
  }
  .header h1 {
    font-size: 20px;
  }

  .invite_section h5 {
    font-size: 16px;
  }

  .form_menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .form_menu > div {
    width: 100%;
  }

  .filter_menu {
    width: 100%;
    margin-left: 0;
  }

  .drop_down,
  .drop_down_select {
    width: 100%;
  }

  .invite_disabled {
    width: 100%;
  }

  .invite {
    width: 100%;
  }

  .coach_roaster {
    padding: 16px;
    width: 100%;
  }

  .student_top_right,
  .top_right {
    width: 100%;
    padding: 0;
  }
  .radioText {
    font-size: 12px;
  }

  .coach_top {
    flex-direction: column;
  }

  .radio_buttons,
  .student_top_right .radio_buttons {
    width: 100%;
    padding: 0 !important;
  }

  .main {
    padding: 16px;
  }

  .table_headers th {
    font-size: 13px;
  }
}
