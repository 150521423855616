.main h6 {
  font-family: Lexend;
  font-size: 20px;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: -0.0031em;
  text-align: left;
  margin-bottom: 16px;
  margin-top: 16px;
}

.main p {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 300;
  line-height: 28px;
}
.border {
  border: 1px solid var(--global-outline-grey, #dae8e7);

  margin-top: 10px;
  margin-bottom: 30px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 0;
}

.score_section {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.scoreboard {
  width: Fill (584px) px;
  padding: 12px 16px 12px 16px;
  gap: 12px;
  border-radius: 8px;
  border: 1px solid var(--global-outline-grey, #dae8e7);
}

.scoreboard p {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.scoreboard p svg {
  width: 18px;
  height: 18px;
  color: #2aa87e;
}
.scoreboard_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.scoreboard_head h4 {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  margin-top: 8px;
  margin-bottom: 8px;
}

.close_icon {
  color: #e03838 !important;
}
