.main {
  display: flex;
  flex-direction: row;
  background: var(--global-light-grey, #f3f8f8);
}

.content {
  height: 100%;
  min-height: 100vh;
  padding: 0 35px;
  width: 90%;
}

.content h1 {
  color: var(--Global-Grey, #243030);

  /* TierOneAI/H4 */
  font-family: Lexend;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%; /* 35px */
  letter-spacing: -0.087px;
}

.content p {
  color: var(--global-body-grey, #5b706f);
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
}

.learner_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.learner_header h2 {
  color: var(--Global-Dark-Grey, #0a1214);
  font-family: Lexend;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
  letter-spacing: -0.075px;
}

.learner_header_buttons {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}
.border {
  border: 1px solid var(--global-outline-grey, #dae8e7);

  margin-top: 10px;
  margin-bottom: 10px;
}

.discard_button {
  display: flex;
  height: 48px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: transparent;
  color: #5b706f;
  border: 1px solid #5b706f;
  cursor: pointer;
}
.discard_button svg {
  width: 16px;
  height: 16px;
}

.comment_button {
  display: flex;
  height: 48px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: transparent;
  color: #5b706f;
  border: 1px solid #5b706f;
  cursor: pointer;
}
.comment_button svg {
  width: 16px;
  height: 16px;
}

.save_button {
  display: flex;
  height: 48px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #5b706f;
  color: #fff;
  border: 1px solid #5b706f;
  cursor: pointer;
}
.save_button svg {
  width: 16px;
  height: 16px;
}

.condition_button {
  display: flex;
  height: 48px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--NFTE-NFTE-Purple, #4c12a1);
  color: #fff;
  border: none;
  cursor: pointer;
}
.condition_button svg {
  width: 16px;
  height: 16px;
}

.dropdown {
  width: 100%;
}

.assement_details {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}

.assement_details > div {
  width: 33%;
}

.assement_list {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #4c12a1;
}

.assement_list > li {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  width: 33%;
  font-family: Lexend;
  font-size: 20px;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: -0.0031em;
  text-align: left;
  color: #5b706f;
  cursor: pointer;
}

.assement_list > li.active {
  color: var(--NFTE-NFTE-Purple, #4c12a1);
  border-bottom: 4px solid #4c12a1;
}

.assement_section_content {
  margin-top: 24px;
}

.question_add {
  width: 98%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border: 1px solid #4c12a1;
  border-radius: 4px;
  cursor: pointer;
}

.question_add p {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  color: #4c12a1;
  margin: 0;
}

.question_add svg {
  width: 24px;
  height: 24px;
  color: #4c12a1;
}

.question_section {
  margin: 24px 0;
  padding: 36px;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.question_format {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}

.question_format button {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  background-color: #4c12a1;
  color: #fff;
  padding: 14px 16px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
}

.question_content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.question_content h3 {
  font-family: Lexend;
  font-size: 24px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: -0.0031em;
  text-align: left;
  color: #243030;
  margin: 0;
}

.question_type {
  font-family: Lexend;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  text-align: left;
  color: #5b706f;
  margin: 0;
}

.question_button {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}

.question_button p,
.question_button button {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  cursor: pointer;
  color: #5b706f;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  margin: 0;
  background-color: transparent;
  border: none;
}

.question_button p svg,
.question_button button svg {
  width: 16px;
  height: 16px;
}

.question_button p.delete svg,
.question_button button.delete svg {
  color: #e03838;
}

.question_button p.rename svg,
.question_button button.rename svg {
  color: #5b706f;
}

.question_button p.save svg,
.question_button button.save svg {
  color: #2aa87e;
}

.new_question_button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.new_question_button .new_question {
  width: 95%;
}

.new_question_button button {
  display: flex;
  height: 50px;
  width: 10%;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  cursor: pointer;
  background: #4c12a1;
  color: #fff;
  border: none;
}

.new_question_button button:disabled {
  background: #dae8e7;
  color: #5b706f;
  cursor: not-allowed;
}

.new_question {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  padding: 16px;
  border: 1px solid #2aa87e;
  border-radius: 4px;
  cursor: pointer;
}

.new_question p {
  margin: 0;
  font-family: Lexend;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  color: #2aa87e;
}

.new_question svg {
  width: 24px;
  height: 24px;
  color: #2aa87e;
}

.question_form {
  border: 1px solid #2aa87e;
  border-radius: 4px;
  padding: 16px;
}

.mcq_preview .mcq_header > p:first-child {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  color: #243030;
}

.mcq_preview .question_button p {
  font-family: Lexend;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}

.mcq_radio > div {
  display: flex;
  flex-direction: column;
}

.mcq_radio > div span {
  font-family: Lexend;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  text-align: left;
}

.mcq_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.mcq_section {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.mcq_question_field {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}

.mcq_question_field p {
  font-family: Lexend;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #5b706f;
  min-width: 64px;
}

.scoring_radio > div {
  border: 1px solid #dae8e7;
  background-color: #fafdfd;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  padding: 12px 16px;
  gap: 16px;
}

.radio_label p {
  margin: 0;
  font-family: Lexend;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #243030;
}

.radio_label p:nth-child(2) {
  font-family: Lexend;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  text-align: left;
  color: #5b706f;
}

.question_flag_section {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 1px solid #dae8e7;
  padding: 16px;
}

.question_flag {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}

.question_flag svg {
  width: 24px;
  height: 24px;
  color: #5b706f;
  cursor: pointer;
}

.modal_title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 90%;
  margin: 0 auto;
}

.modal_title h5 {
  color: var(--TierOneAI-Grey, #243030);
  text-align: center;
  font-family: Lexend;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
  letter-spacing: -0.075px;
  margin: 0;
}

.modal_title p {
  color: var(--TierOneAI-Body-Grey, #5b706f);
  text-align: center;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
  margin: 0;
}

.modal_buttons .remove {
  display: flex;
  height: 48px;
  padding: 8px 36px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  background: var(--TierOneAI-Error-Red, #e03838);
  color: var(--Global-White, #fff);
  cursor: pointer;
  border: none;
}

.modal_buttons .cancel {
  display: flex;
  height: 48px;
  padding: 8px 36px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid var(--Global-Grey, #243030);
  background: var(--TierOneAI-White, #fff);

  cursor: pointer;
}

.modal_buttons {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-top: 30px;
}

.review_section {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.indicator_section,
.mcq_review_section,
.total_scoring_section {
  display: flex;
  flex-direction: column;
  gap: 8px;

  background-color: #ffffff;
  border: 1px solid #dae8e7;
  border-radius: 8px;
  padding: 32px;
}

.summary_table {
  display: flex;
  flex-direction: column;
  width: 100%;

  overflow-x: auto;
}

.summary_table table {
  border-spacing: 0 8px;
}
.summary_table th {
  border-bottom: 1px solid var(--global-outline-grey, #dae8e7);
}
.summary_table thead th {
  padding-bottom: 10px;
  text-align: center;
  color: var(--Global-Grey, #243030);
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 175%;
}
.summary_table thead th:first-child {
  text-align: left;
}

.summary_table thead th:last-child,
.summary_table tbody td:last-child {
  text-align: right;
}

.summary_table table tbody tr:last-child td {
  border-top: 1px solid #dae8e7;
  font-family: Lexend;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.summary_table table tr td {
  text-align: center;
  color: #243030;
}
.summary_table table tr td:first-child {
  text-align: left;
}

.summary_table table tr {
}

.summary_table table tr.coachrow td {
}

.data {
  color: var(--global-body-grey, #5b706f);
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
}

.mcq_review_section .review_header p,
.question_flag_section .review_header p,
.total_scoring_section .review_header p {
  font-family: Lexend;
  font-size: 24px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: -0.0031em;
  text-align: left;
  color: #243030;
  margin: 0;
}

.mcq_review_section .review_header p:last-child {
  font-family: Lexend;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  text-align: left;
}

.review_title_h2 {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  color: #243030;
  margin: 0;
}

.review_title_h2:nth-child(1),
.review_title_h2:nth-child(2) {
  margin-top: 24px;
}

.review_section_title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.review_section_title div {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  font-family: Lexend;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #5b706f;
  cursor: pointer;
}

.review_section_title p {
  margin: 0;
}

.scoring_section {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.scoring_bracket_section {
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: 1px solid #dae8e7;
  border-radius: 8px;
  padding: 16px;
  background-color: #fafdfd;
}

.scoring_bracket {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}

.scoring_bracket p {
  font-family: Lexend;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #5b706f;
}

.scoring_bracket p:first-child {
  color: #243030;
}

.scoring_bracket > div:nth-child(2) {
  width: 46%;
}

.scoring_bracket_section:last-child .scoring_bracket > div:nth-child(2) {
  width: 47%;
}

.scoring_bracket svg {
  width: 24px;
  height: 24px;
  color: #5b706f;
  cursor: pointer;
}

.review_list ul {
  padding: 0;
  margin: 0;
  margin-left: 16px;
}

.review_list ul li {
  font-family: Lexend;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  text-align: left;
  color: #5b706f;
}

.input_tab {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin-bottom: 16px;
}

.modal_comment_section {
  margin-top: 20px;
  max-height: 70vh;
}
.modal_comment_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.modal_comment_header p {
  color: var(--TierOneAI-Dark-Grey, #0a1214);
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
  letter-spacing: -0.062px;
}

.modal_comment_header span {
  color: var(--TierOneAI-Body-Grey, #5b706f);
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.modal_border {
  border: 1px solid var(--Global-Outline-Grey, #dae8e7);
}
.post_body_head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.post_body_head_left,
.post_body_image,
.role {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  gap: 12px;
  justify-content: center;
}

.post_body_head img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.profile h4 {
  color: var(--global-grey, #243030);

  margin: 0;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 175%;
}
.profile p {
  color: var(--global-body-grey, #5b706f);

  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  margin: 0;
}

.post_body_head_left span {
  border-radius: 48px;
  border: 1px solid var(--global-grey, #243030);
  background: var(--global-grey, #243030);
  display: flex;
  padding: 2px 12px;
  align-items: center;
  gap: 4px;

  color: var(--global-white, #fff);
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.post_date h4 {
  color: var(--global-body-grey, #5b706f);
  text-align: right;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
  margin: 0;
}

.post_date p {
  color: var(--global-body-grey, #5b706f);
  text-align: right;
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  margin: 0;
}

.modal_comment_body p {
  color: var(--TierOneAI-Body-Grey, #5b706f);
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
}

.modal_bottom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  gap: 12px;
}

.reply {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;

  background: #4c12a1;

  color: var(--Global-White, #fff);
  cursor: pointer;
  border: none;
  width: 100%;
}

.collapse {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
  background: var(--TierOneAI-Light-Grey, #f3f8f8);
  color: #4c12a1;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  border: none;
  width: 100%;
}

.generate svg {
  width: 16px;
  height: 16px;
}

.reply_field {
  width: 100%;
}
/* 
.aiChat {
  height: 80vh;
  overflow-y: scroll;
} */

.reply_section {
  position: absolute;
  bottom: 16px;
  left: 16px;
  right: 16px;
}

.bottom_section {
  position: absolute;
  bottom: 16px;
  left: 16px;
  right: 16px;
}
.comment_button {
  border-radius: 8px;
  padding: 10px 20px;
  color: #5b706f;
  font-family: Lexend;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  border: 1px solid #969898;
  cursor: pointer;
}

.tip_section {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
}

@media (max-width: 425px) {
  .content {
    padding: 0 16px;
  }
  .learner_header h2 {
    font-size: 17px;
  }
  .learner_header {
    flex-direction: column;
    gap: 16px;
  }
  .search {
    width: 90%;
  }
  .learner_header_buttons {
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }

  .coach_roaster {
    padding: 20px;
  }

  .summary_table thead th {
    font-size: 10px;
  }

  .modal_view {
    width: 80%;
  }
}
