.app-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.header-text {
    display: flex;
    font-family: 'Roobert';
    font-style: normal;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.sub-text {
    font-family: 'Roobert';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 144%;
    text-align: center;
    letter-spacing: -0.0125em;
    color: #4f4c4d;
}

.email-input {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 12px 16px;
    gap: 8px;
    width: 512px;
    height: 53px;
    background: #ffffff;
    border: 1px solid #617388;
    border-radius: 4px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    font-size: 20px;
}

.button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 36px;
    gap: 12px;
    width: 267px;
    height: 64px;
    background: #00BF8C;
    border-radius: 2px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.button:hover {
    cursor: pointer;
}

.button-text {
    font-family: 'Roobert';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 144%;
    text-align: center;
    hanging-punctuation: first last;
    color: #231f20;
}

.persona-input {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    gap: 8px;
    width: 720px;
    height: 53px;
    background: #ffffff;
    border: 1px solid #617388;
    border-radius: 4px;
    flex: none;
    order: 0;
    flex-grow: 1;
}

.persona-input select {
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    font-size: 16px;
    color: #000000;
}

.question-input {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    gap: 8px;
    width: 720px;
    height: 53px;
    background: #ffffff;
    border: 1px solid #617388;
    border-radius: 4px;
    flex: none;
    order: 0;
    flex-grow: 1;
    margin-top: 15px;
    font-family: 'Roobert';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
}

.answer-input {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 12px 16px;
    gap: 8px;
    width: 720px;
    height: 144px;
    background: #FFFFFF;
    border: 1px solid #617388;
    border-radius: 4px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-top: 15px;
    position: relative;
    font-family: 'Roobert';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 144%;
  }
  
  .textarea-field {    
    /* Element */
    width: 100%;
    height: 100%;
    font-family: 'Roobert';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 144%;
    color: rgba(102, 112, 133, 0.75);
  }

  .loading-spinner {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 3px solid #f3f3f3;
    border-radius: 50%;
    border-top: 3px solid #3498db;
    animation: spin 1s linear infinite;
  }

  .loading-message {
    margin-top: 10px;
    margin-left: 10px;
    font-size: 16px;
    color: #fff;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }  