.all {
  display: flex;
  flex-direction: row;

  background: var(--global-light-grey, #f3f8f8);
}
.announcement_section {
  height: 100%;
  min-height: 100vh;
  padding: 0 16px;
  width: 90%;
  box-sizing: border-box;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 15px !important;
}
.header h4 {
  color: var(--global-grey, #243030);
  font-family: Lexend;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
  letter-spacing: -0.087px;
  margin: 0;
}

.header_right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.add_post {
  display: flex;
  height: 48px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--nftenfte-purple, #4c12a1);
  color: #fff;
  border: none;
  cursor: pointer;
  color: var(--global-white, #fff);
  text-align: center;

  /* TierOneAI/Button */
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.disableButton {
  display: flex;
  height: 48px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: none;
  text-align: center;

  /* TierOneAI/Button */
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: not-allowed;
  background: var(--global-light-grey, #f3f8f8);
  color: var(--global-body-grey, #5b706f);
}

.add_post svg {
  width: 16px;
  height: 16px;
  color: #fff !important;
}

.border_line {
  border: 1px solid var(--global-outline-grey, #dae8e7);
}

.post {
  width: 60%;
  height: 100%;
  border-radius: 8px;
  border: 1px solid var(--global-outline-grey, #dae8e7);
  background: var(--global-white, #fff);
  padding: 40px 48px;
  margin: 30px auto;
}

.post_header h4 {
  color: var(--global-grey, #243030);

  /* TierOneAI/H4 */
  font-family: Lexend;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%; /* 35px */
  letter-spacing: -0.087px;
}

.post_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.header_right svg {
  width: 16px;
  height: 16px;
  color: var(--global-body-grey, #5b706f);

  /* TierOneAI/Element */
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%; /* 24.5px */
}

.header_right span {
  color: var(--global-body-grey, #5b706f);

  /* TierOneAI/Body */
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%; /* 24.5px */
}

.post_body_head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.post_body_head_left,
.post_body_image,
.role {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  gap: 12px;
  justify-content: center;
}

.post_body_head img {
  width: 32px;
  height: 32px;
}

.profile h4 {
  color: var(--global-grey, #243030);

  margin: 0;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 175%;
}
.profile p {
  color: var(--global-body-grey, #5b706f);

  /* TierOneAI/Fine Print */
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%; /* 18px */
  margin: 0;
}

.post_body_head_left span {
  border-radius: 48px;
  border: 1px solid var(--global-grey, #243030);
  opacity: 0.8;
  background: var(--global-grey, #243030);
  display: flex;
  padding: 2px 12px;
  align-items: center;
  gap: 4px;

  color: var(--global-white, #fff);
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.post_date h4 {
  color: var(--global-body-grey, #5b706f);
  text-align: right;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
  margin: 0;
}

.post_date p {
  color: var(--global-body-grey, #5b706f);
  text-align: right;
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  margin: 0;
}

.post_body_head p {
  color: var(--global-form-grey, #8ca19f);
  text-align: right;

  /* TierOneAI/Fine Print */
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%; /* 18px */
}

.post_body_content p {
  color: var(--global-body-grey, #5b706f);

  /* TierOneAI/P */
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%; /* 28px */
}

.react_sections {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.react_img {
  width: 24px;
  height: 24px;
  color: var(--global-body-grey, #5b706f);
  border: 1px solid #fff;
  border-radius: 50%;
  position: relative;
}

.react_section img:nth-child(1) {
  z-index: 6;
}

.react_section img:nth-child(2) {
  z-index: 5;
  margin-left: -8px;
}

.react_section img:nth-child(3) {
  z-index: 4;
  margin-left: -8px;
}

.react_section img:nth-child(4) {
  z-index: 3;
  margin-left: -8px;
}

.react_section img:nth-child(5) {
  z-index: 2;
  margin-left: -8px;
}

.react_section img:nth-child(6) {
  z-index: 1;
  margin-left: -8px;
}

.reactions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}
.create_post {
  width: 60%;
  height: 100%;
  border-radius: 8px;
  border: 1px solid var(--global-outline-grey, #dae8e7);
  background: var(--global-white, #fff);
  display: flex;
  padding: 40px 48px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  margin: 15px auto;
}

.likeComment {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.likeComment svg {
  width: 16px;
  height: 16px;
  color: var(--global-body-grey, #5b706f);
}

.like {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  justify-content: center;
  color: var(--global-body-grey, #5b706f);
  cursor: pointer;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
}

.selectLike {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  justify-content: center;
  color: #4c12a1;
  cursor: pointer;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
}

.selectLike svg {
  color: #4c12a1;
}

.comment {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  justify-content: center;
  color: var(--global-body-grey, #5b706f);

  cursor: pointer;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
}

.selectComment {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  justify-content: center;
  color: #4c12a1;

  cursor: pointer;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
}

.selectComment svg {
  color: #4c12a1;
}

.post_body_content {
  transition: all 0.3s ease-in-out;
}

.post_content {
  max-height: 220px;
  overflow: hidden;
  max-height: all 0.5s cubic-bezier(0, 1, 0, 1);
}

.post_content_expanded {
  max-height: 100%;
  overflow: hidden;
  transition: all 1s ease-in-out;
}

.buttonExpand {
  border: none;
  background: none;
  cursor: pointer;
  color: var(--global-form-grey, #8ca19f);

  /* TierOneAI/Element */
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%; /* 24.5px */
}

.button_section {
  display: flex;
  flex-direction: row;

  align-items: center;
}

.button_section svg {
  width: 16px;
  height: 16px;
  color: var(--global-body-grey, #5b706f);
}
.replyfield {
  margin-top: 20px;
}

.student_profile {
  display: flex;
  flex-direction: row;

  align-items: center;
  gap: 10px;
}

.student_profile img {
  width: 32px;
  height: 32px;
}

.student_profile span {
  color: var(--global-body-grey, #5b706f);

  /* TierOneAI/Fine Print */
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%; /* 18px */
  border-radius: 48px;
  border: 1px solid var(--global-outline-grey, #dae8e7);
  opacity: 0.8;
  display: flex;
  padding: 2px 12px;
  align-items: center;
  gap: 4px;
}

.top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.date {
  display: flex;
  flex-direction: row;
  color: var(--global-form-grey, #8ca19f);
  text-align: right;
}

.date p {
  margin: 0;
  /* TierOneAI/Fine Print */
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%; /* 18px */
}

.return {
  display: flex;
  height: 48px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--global-body-grey, #5b706f);
  color: var(--global-body-grey, #5b706f);
  text-align: center;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.return svg {
  width: 16px;
  height: 16px;
  color: var(--global-body-grey, #5b706f);
}

.create_post h2 {
  color: var(--global-grey, #243030);
  font-family: Lexend;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
  letter-spacing: -0.087px;
}

.create_post_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.create_post_header svg {
  color: var(--global-body-grey, #5b706f);
  cursor: pointer;
}

.publish {
  margin-left: 10px;
}

.reply {
  gap: 8px;
  color: '#4C12A1';
}

.disableReplyButton {
  gap: 8px;
  color: var(--global-body-grey, #5b706f);
  cursor: not-allowed;
}

.reply svg {
  width: 16px;
  height: 16px;
  color: var(--nftenfte-purple, #4c12a1);
}

@media screen and (max-width: 1024px) {
  .post {
    width: 90%;
    padding: 40px;
    box-sizing: border-box;
  }

  .post_header h4 {
    font-size: 16px;
  }
  .post_body_content p {
    font-size: 13px;
  }
  .create_post {
    width: 90%;
    padding: 40px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 768px) {
  .post {
    width: 80%;
    padding: 40px;
    box-sizing: border-box;
  }
  .post_header h4 {
    font-size: 26px;
    margin: 0;
  }

  .post_body_head_left span {
    font-size: 12px;
  }

  .post_date h4 {
    font-size: 10px;
  }
  .post_body_content p {
    font-size: 12px;
  }
  .create_post {
    width: 80%;
    padding: 40px;
    box-sizing: border-box;
  }
  .react_img {
    width: 15px;
    height: 15px;
  }
}

@media screen and (max-width: 425px) {
  .header h4 {
    font-size: 24px;
  }

  .add_post {
    font-size: 11px;
  }

  .post {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
  }

  .post_body_head_left {
    flex-direction: column;
    align-items: flex-start;
  }

  .role {
    gap: 4px;
  }

  .react_sections {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 2px;
    margin: 5px 0;
  }

  .post_body_content p {
    margin: 0;
  }
  .create_post {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }
  .create_post h2 {
    font-size: 21px;
  }
  .disableButton {
    font-size: 11px;
  }

  .announcement_section {
    margin-top: 60px;
    width: 100%;
  }

  .header {
    flex-direction: column;
    gap: 20px;
  }
  .post_header {
    flex-direction: column-reverse;
    gap: 7px;
    align-items: flex-end;
  }

  .header_right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
  }

  .reactions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }

  .publish {
    margin: 0 auto;
  }
}
