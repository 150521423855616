.main {
  display: flex;
  flex-direction: row;
  background: var(--global-light-grey, #f3f8f8);
}

.content {
  height: 100%;
  min-height: 100vh;
  padding: 0 35px;
  width: 90%;
}

.content h1 {
  color: var(--Global-Grey, #243030);

  /* TierOneAI/H4 */
  font-family: Lexend;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%; /* 35px */
  letter-spacing: -0.087px;
}

.content p {
  color: var(--global-body-grey, #5b706f);
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
}

.learner_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.learner_header h2 {
  color: var(--Global-Dark-Grey, #0a1214);
  font-family: Lexend;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
  letter-spacing: -0.075px;
}

.input_fields {
  display: flex;
  flex-direction: row;
  gap: 16px;

  width: 100%;
}

.search_input {
  width: 100%;
  height: 48px;
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid var(--global-outline-grey, #dae8e7);
  background: var(--global-white, #fff) !important;
}
.coach_roaster {
  display: flex;

  padding: 36px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  border-radius: 8px;
  border: 1px solid var(--global-outline-grey, #dae8e7);
  background: var(--global-white, #fff);
  margin-top: 20px;
}

.learner_table {
  display: flex;
  flex-direction: column;
  width: 100%;

  overflow-x: auto;
}

.learner_table table {
  border-spacing: 0;
  padding-bottom: 220px;
}
.learner_table th {
  border-bottom: 1px solid var(--global-outline-grey, #dae8e7);
}
.learner_table thead th {
  padding-bottom: 10px;
  text-align: center;
  color: var(--Global-Grey, #243030);
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 175%;
}
.learner_table thead th:first-child {
  text-align: left;
}

.learner_table thead th:last-child {
  text-align: right;
}

.learner_table table tr td {
  text-align: center;
}
.learner_table table tr td:first-child {
  text-align: left;
}

.learner_table table tr {
  padding-bottom: 20px;
}

.learner_table table tr.coachrow td {
}

.data {
  color: var(--global-body-grey, #5b706f);
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
}

.checkBox {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
}
.status {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

.action {
  position: absolute;
  right: 0;
  top: 45px;
  background-color: #fff;
  padding: 8px;
  border: 1px solid #dae8e7;
  border-radius: 4px;
  z-index: 1;
}

.action ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.action ul li {
  padding: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.action ul li:hover {
  background-color: #f3f8f8;
}

.status button {
  all: unset;
  color: var(--nftenfte-dark-blue, #055370);
  cursor: pointer;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
}

.status img {
  width: 16px;
  height: 16px;
}

.resend {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.resend svg {
  width: 16px;
  height: 16px;
  color: #5b706f;
}

.resend button,
.resend a {
  color: #5b706f;

  /* TierOneAI/Element */
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%; /* 24.5px */
  text-decoration: none;
}
.empty_table {
  width: 100%;
  border: 1px solid var(--global-outline-grey, #dae8e7);
  background: var(--global-light-grey, #f3f8f8);
  padding: 36px 0px;
}
.empty_table p {
  text-align: center;
}

.link {
  color: var(--NFTE-NFTE-Purple, #4c12a1) !important;

  font-family: Lexend;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
  letter-spacing: -0.075px;
  cursor: pointer;
}
.search {
  width: 512px;

  background: #fff;
}

.learner_header_buttons {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}
.iteration {
  display: flex;
  height: 48px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--NFTE-NFTE-Purple, #4c12a1);
  color: #fff;
  border: none;
  cursor: pointer;
}
.iteration svg {
  width: 16px;
  height: 16px;
}

.review,
.progress,
.done {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: center;
}
.review svg,
.progress svg,
.done svg {
  width: 16px;
  height: 16px;
}

.progress {
  color: #f6a623;
}

.review {
  fill: #a57ff6;
  color: #a57ff6;
}

.done {
  color: #30bf8f;
}

.border {
  border: 1px solid var(--global-outline-grey, #dae8e7);

  margin-top: 10px;
  margin-bottom: 30px;
}

.modal_title h3 {
  font-family: Lexend;
  font-size: 20px;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: -0.0031em;
  color: #243030;
}

.modal_buttons {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}

.modal_buttons > div {
  width: 70%;
}

.create {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  padding: 14px 16px;
  background-color: var(--NFTE-NFTE-Purple, #4c12a1);
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.create:disabled {
  background-color: #d3d3d3;
  color: #fff;
  cursor: not-allowed;
}

.modal_title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 90%;
  margin: 0 auto;
}

.modal_title h5 {
  color: var(--TierOneAI-Grey, #243030);
  text-align: center;
  font-family: Lexend;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
  letter-spacing: -0.075px;
  margin: 0;
}

.modal_title p {
  color: var(--TierOneAI-Body-Grey, #5b706f);
  text-align: center;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
  margin: 0;
}

.modal_buttons .remove {
  display: flex;
  height: 48px;
  padding: 8px 36px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  background: var(--TierOneAI-Error-Red, #e03838);
  color: var(--Global-White, #fff);
  cursor: pointer;
  border: none;
}

.modal_buttons .cancel {
  display: flex;
  height: 48px;
  padding: 8px 36px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid var(--Global-Grey, #243030);
  background: var(--TierOneAI-White, #fff);

  cursor: pointer;
}

.modal_buttons {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-top: 30px;
}

@media (max-width: 425px) {
  .content {
    padding: 0 16px;
  }
  .learner_header h2 {
    font-size: 17px;
  }
  .learner_header {
    flex-direction: column;
    gap: 16px;
  }
  .search {
    width: 90%;
  }
  .learner_header_buttons {
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }

  .coach_roaster {
    padding: 20px;
  }

  .learner_table thead th {
    font-size: 10px;
  }

  .modal_view {
    width: 80%;
  }
}
