.main {
  display: flex;
  flex-direction: row;
  background: var(--global-light-grey, #f3f8f8);
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.content {
  height: 100%;
  min-height: 100vh;
  padding: 0 35px;
  width: 90%;
}

.transcript_head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.transcript_head audio {
  height: 36px;
  color: #8ca19f;
}

.return {
  width: 280px;
  padding: 14px;
  border-radius: 8px;
  font-family: Lexend;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: #5b706f;
  border: 1px solid #5b706f;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 8px;
}

.return svg {
  width: 12px;
  height: 12px;
}
.content h1 {
  color: var(--Global-Grey, #243030);
  font-family: Lexend;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
  letter-spacing: -0.087px;
}

.content p {
  color: var(--global-body-grey, #5b706f);
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
}

.learner_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.learner_header h2 {
  color: var(--Global-Dark-Grey, #0a1214);
  font-family: Lexend;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
  letter-spacing: -0.075px;
}
.coach_roaster {
  display: flex;

  padding: 36px;
  flex-direction: row;
  align-items: flex-start;
  gap: 70px;
  border-radius: 8px;
  border: 1px solid var(--global-outline-grey, #dae8e7);
  background: var(--global-white, #fff);
  margin-top: 20px;
}
.border {
  border: 1px solid var(--global-outline-grey, #dae8e7);

  margin-top: 10px;
  margin-bottom: 30px;
}
.analyzer_section {
  width: 100%;
}

.course_list {
  width: 100%;
}
.course_list ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  gap: 50px;
  border-bottom: 1px solid var(--NFTE-NFTE-Blue, #0aa6e0);
  /* margin-top: 24px; */
  width: 100%;
}

.course_list li {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  cursor: pointer;

  font-family: Lexend;
  font-size: 16px;
  color: #243030;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  width: 50%;
}

.course_list li.active {
  border-bottom: 4px solid var(--NFTE-NFTE-Blue, #0aa6e0);
}

.course_list li p {
  color: var(--Global-Grey, #243030);
  /* TierOneAI/Fine Print Bold */
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
}
.container {
  background: var(--Global-White, #fff);
  position: relative;
  /* height: 85vh; */
  padding: 0 16px;
}

.container p {
  color: var(--Global-Body-Grey, #5b706f);

  /* TierOneAI/P */
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%; /* 28px */
}
.container_head h2 {
  font-family: Lexend;
  font-size: 28px;
  font-weight: 300;
  line-height: 35px;
  letter-spacing: -0.0031em;
  text-align: left;
  margin-top: 0;
}

.container_head p {
  font-family: Lexend;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.coach_roaster_left {
  width: 50%;
}

.coach_roaster_right {
  width: 50%;
}

.transcript_head h6 {
  font-family: Lexend;
  font-size: 20px;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: -0.0031em;
  text-align: left;
  margin-top: 0;
  margin-bottom: 18px;
}

.transcript_body {
  justify-content: center;
  align-items: center;

  height: 435px;
  border: 1px solid var(--global-outline-grey, #dae8e7);
  border-radius: 8px;
  padding: 24px;
}

.chat_box img {
  width: 24px;
  height: 24px;
}

.chat_box_left {
  display: flex;
  flex-direction: row;
  text-align: left;
  gap: 8px;
  align-items: center;
}

.chat_box_left p {
  width: Hug (61px) px;
  height: Hug (37px) px;
  padding: 8px 12px 8px 12px;
  gap: 8px;
  border-radius: 4px;
  background: #f3f8f8;
}
.chat_box_right {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;
  align-items: center;
}

.chat_box_right p {
  width: Hug (358px) px;
  height: Hug (37px) px;
  padding: 8px 12px 8px 12px;
  gap: 8px;
  border-radius: 4px;
  background: #5b706f;
  color: #fff;
}
.chat_header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.chat_box_text span {
  font-family: Lexend;
  font-size: 9px;
  font-weight: 300;
  line-height: 12px;
  text-align: left;
}

.chat_box_text_reply {
  font-family: Lexend;
  font-size: 9px;
  font-weight: 300;
  line-height: 12px;
  text-align: right;
}

.icon_active {
  color: #0aa6e0;
}

.course_list svg {
  width: 16px;
  height: 16px;
  color: #8ca19f;
}
