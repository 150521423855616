.main {
  display: flex;
  flex-direction: row;
  background: var(--global-light-grey, #f3f8f8);
}

.content {
  height: 100%;
  min-height: 100vh;
  padding: 0 35px;
  width: 90%;
}

.content h1 {
  color: var(--Global-Grey, #243030);

  /* TierOneAI/H4 */
  font-family: Lexend;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%; /* 35px */
  letter-spacing: -0.087px;
}

.content p {
  color: var(--global-body-grey, #5b706f);
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
}

.learner_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.learner_header h2 {
  color: var(--Global-Dark-Grey, #0a1214);
  font-family: Lexend;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
  letter-spacing: -0.075px;
}
.coach_roaster {
  display: flex;

  padding: 36px;
  flex-direction: row;
  align-items: flex-start;
  gap: 70px;
  border-radius: 8px;
  border: 1px solid var(--global-outline-grey, #dae8e7);
  background: var(--global-white, #fff);
  margin-top: 20px;
}

.coach_roaster_left {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 50%;
}

.coach_roaster_right {
  width: 50%;
}
/* .personalities {
  width: 80%;
} */
.coach_roaster h3 {
  font-family: Lexend;
  font-size: 20px;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: -0.0031em;
  text-align: left;
}
.learner_table {
  display: flex;
  flex-direction: column;
  width: 100%;

  overflow-x: auto;
}

.personalities {
  height: 50px;
  padding: 12px 16px 12px 16px;
  gap: 12px;
  border-radius: 8px;
  border: 1px solid var(--global-outline-grey, #dae8e7);
  display: flex;
  justify-content: space-between;
  align-items: center;

  flex-direction: row;
}
.past_calls {
  width: 100%;
}

.past_calls h6 {
  font-size: 20px;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: -0.0031em;
  text-align: left;
  margin-bottom: 16px;
  margin-top: 16px;
}
.profile {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
}

.profile h4 {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;

  margin: 0;
}

.profile p {
  margin: 0;
  font-family: Lexend;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  text-align: left;
}
.profile img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.profile_right span {
  width: Hug (80px) px;
  height: Hug (21px) px;
  padding: 0px 16px 0px 16px;
  gap: 8px;
  border-radius: 32px;
  border: 1px solid var(--global-outline-grey, #dae8e7);
  background-color: #f3f8f8;

  font-family: Lexend;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  text-align: left;
}
.border {
  border: 1px solid var(--global-outline-grey, #dae8e7);

  margin-top: 10px;
  margin-bottom: 30px;
}

.profile_right {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
}

.profile_right img {
  width: 24px;
  height: 24px;
}

.past_call_list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.call_left {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
}

.call_left h4 {
  font-family: Lexend;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #5b706f;
  margin: 8px;
}
.call_left img {
  width: 24px;
  height: 24px;
}

.call_right {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.call_right span {
  font-family: Lexend;
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  text-align: right;
  color: #5b706f;
}

.call_right svg {
  width: 16px;
  height: 16px;
  color: #5b706f;
}

.roleplay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 445px;
  border: 1px solid var(--global-outline-grey, #dae8e7);
  border-radius: 8px;
  padding: 24px;
}

.roleplay h1 {
  font-family: Lexend;
  font-size: 24px;
  font-weight: 500;
  line-height: 125%;
  letter-spacing: -0.075px;
  text-align: center;
  color: #5b706f;
}
.roleplay h5 {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
  margin: 0;
}

.roleplay p {
  font-family: Lexend;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  text-align: center;
  width: 400px;
  margin-top: 0;
}

.roleplay h6 {
  font-family: Lexend;
  font-size: 20px;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: -0.0031em;
  text-align: center;
  color: #4c12a1;
  margin-top: 20px;
  margin-bottom: 0px;
}
.roleplay img {
  width: 128px;
  height: 128px;
}

.roleplay span {
  width: Hug (80px) px;
  height: Hug (21px) px;
  padding: 0px 16px 0px 16px;
  gap: 8px;
  border-radius: 32px;
  border: 1px solid var(--global-outline-grey, #dae8e7);
  background-color: #f3f8f8;

  font-family: Lexend;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  text-align: left;
}

.call_button {
  /* margin-top: 60px; */
  width: 100%;
}
.call_button button {
  padding: 8px 16px 8px 16px;
  gap: 8px;
  border-radius: 8px;
  color: #fff;
  font-family: Lexend;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  background: #4c12a1;
  border: none;
  cursor: pointer;
  width: 100%;
  height: 48px;
  margin-top: 68px;
}

.end_button {
  background: #e03838 !important;
  margin-top: 40px !important;
}

.view_icon {
  cursor: pointer;
}

.view_icon {
  color: #4c12a1 !important;
}

.expand {
  display: flex;
  justify-content: center;
}
.expand button {
  display: flex;
  background: none;
  border: none;
  cursor: pointer;
  font-family: Lexend;
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  text-align: center;
  align-items: center;
}

.expand button svg {
  width: 18px;
  height: 18px;
  color: #5b706f;
}

.profile_right img {
  cursor: pointer;
}
@media (max-width: 425px) {
  .content {
    padding: 0 16px;
  }
  .learner_header h2 {
    font-size: 17px;
  }
  .learner_header {
    flex-direction: column;
    gap: 16px;
  }
  .search {
    width: 90%;
  }
  .learner_header_buttons {
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }

  .coach_roaster {
    padding: 20px;
  }

  .learner_table thead th {
    font-size: 10px;
  }

  .modal_view {
    width: 80%;
  }
}
