.header h4 {
  font-family: Lexend;
  font-size: 28px;
  font-weight: 300;
  line-height: 35px;
  letter-spacing: -0.0031em;
  text-align: left;
}

.header {
  border-bottom: 1px solid #dae8e7;
  margin-bottom: 16px;
}

.overview_section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
}

.overview_item {
  width: 18%;
  padding: 36px;
  background: #fff;
  border-radius: 8px;
  box-sizing: border-box;
}

.overview_item h6 {
  margin: 0;
  font-family: Lexend;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #5b706f;
}

.overview_item p {
  margin: 0;
  font-family: Lexend;
  font-size: 36px;
  font-weight: 300;
  line-height: 45px;
  letter-spacing: -0.0063em;
  text-align: left;
}

/* .overview_item p:last-child {
  margin: 0;
  font-family: Lexend;
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #8ca19f;
} */

.overview_table_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.overview_table_section button,
.overview_full_table button {
  height: 40px;
  padding: 14px 28px;
  margin: 0;
}

.overview_table_section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.overview_table_item {
  width: 49%;
  padding: 36px;
  background: #fff;
  border-radius: 8px;
  box-sizing: border-box;
}

.overview_table_item h6 {
  margin: 0;
  font-family: Lexend;
  font-size: 24px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: -0.0031em;
  text-align: left;
  color: #243030;
}

.overview_table_item table {
  margin-top: 16px;
}

.overview_table_item table,
.overview_table_item thead {
  width: 100%;
  display: block;
}

.overview_table_item tr {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
}

.overview_table_item tr th,
.overview_table_item tr td {
  width: 20%;
}

.overview_table_item tr th:last-child,
.overview_table_item tr td:last-child {
  text-align: right;
}

.overview_table_item tr th {
  font-family: Lexend;
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #0a1214;
}

.overview_table_item thead tr {
  border-bottom: 1px solid #dae8e7;
}

.overview_table_item tr td {
  font-family: Lexend;
  font-size: 14px;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #5b706f;
}

.overview_full_table {
  width: 100%;
  background: #fff;
  border-radius: 8px;
  box-sizing: border-box;
  margin-top: 16px;
}

.overview_filter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
