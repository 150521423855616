.query {
  display: flex;
  flex-direction: row;
  background: var(--global-light-grey, #f3f8f8);
}

.query__container {
  display: flex;
  width: 90%;
  flex-direction: column;
  padding: 32px;
  height: 95vh;
}

.query__container__header h4 {
  color: var(--global-grey, #243030);
  font-family: Lexend;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
  letter-spacing: -0.087px;
  margin: 0;
}
.query__container__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.query_header__right {
  display: flex;
  flex-direction: row;
  gap: 15px;
}
.add_query {
  border-radius: 8px;
  background: var(--nftenfte-purple, #4c12a1);
  color: var(--global-white, #ffffff);
  text-align: center;
  font-family: Lexend;
  font-size: 16px;
  width: 144px;
  height: 48px;
  border: none;
}

.buttons label {
  color: var(--global-body-grey, #5b706f);
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
}
.border {
  margin-top: 16px;
  border-bottom: 1px solid var(--global-outline-grey, #dae8e7);
}

.query__container__body {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
}

.query__container__body h4 {
  color: var(--global-grey, #243030);
  font-family: Lexend;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
  letter-spacing: -0.087px;
  margin: 0;
}

.query__container__body p {
  color: var(--global-body-grey, #5b706f);
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
}

.qus_section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  /* width: 100%; */
  padding: 36px;
  border: 1px solid var(--global-outline-grey, #dae8e7);
  background: var(--global-white, #ffffff);
  justify-content: center;
}

.qus_button {
  border-radius: 4px;
  border: 1px solid var(--global-outline-grey, #dae8e7);
  background: var(--global-pale-grey, #fafdfd);
  width: 32%;
  height: 57px;
  cursor: pointer;
}

.qus_button_selected {
  border-radius: 4px;
  width: 32%;
  height: 57px;
  cursor: pointer;
  background: var(--nftenfte-purple, #4c12a1);
  color: var(--global-white, #ffffff);
  border: none;
}

.seemore_option {
  color: var(--nftenfte-dark-blue, #055370);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  margin-bottom: 10px;
}

.bottom_section {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.bottom_section__button {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
}

.bottom_section__button input {
  height: 30px;
  width: 35%;
}

.bottom_section__button input[type='radio'] {
  height: 30px;
  width: 16px;
}

.bottom_section__button__input {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;
  align-items: center;
}

.ask_qus {
  width: 65%;
  height: 108px;
  padding: 10px;
}

.submit_qus {
  width: 35%;
  display: flex;
  flex-direction: column;
}

.options {
  display: flex;
  flex-direction: row;
  gap: 10px;
  border-radius: 4px;
  background: var(--global-light-grey, #f3f8f8);
  justify-content: center;
  align-items: center;
  padding-left: 10px;
}
.submit {
  width: 100%;
}
.drop_down svg {
  position: absolute;
  right: 0;
  left: 10;
  color: #8a8484;
  top: 10px;
  font-size: 25px;
  margin-right: 8px;
}

.back {
  display: flex;
  height: 48px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid var(--global-body-grey, #5b706f);
  width: 222px;
  cursor: pointer;
}

.back svg {
  width: 14px;
  height: 14px;
}

.disable_button {
  border-radius: 8px;
  height: 48px;
  margin-top: 20px;
  background: var(--global-light-grey, #f3f8f8);
  color: var(--global-body-grey, #5b706f);
  cursor: not-allowed;
}

.error {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #e03838;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 16px;
  width: 100%;
  padding: 8px 16px;
  color: #fff;
}

.error p {
  font-family: 'Lexend';
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
  color: #fff !important;
  margin: 0;
}

.error svg {
  cursor: pointer;
}

.modal_view {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(155, 151, 151, 0.2);
}

.modal_content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.modal_content h2 {
  font-family: Lexend;
  font-size: 20px;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: -0.0031em;
  text-align: left;
}

.file_uploading {
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: center; */
  padding: 16px;
}
.file_uploading h3 {
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: center;
  align-items: center;
  font-family: Lexend;
  font-size: 16px;
  font-weight: 300;
  line-height: 28px;
  align-items: center;
  color: #5b706f;
}

.file_uploading p {
  color: var(--global-body-grey, #5b706f);
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
  margin-left: 120px;
  text-align: left !important;
  margin-top: 4px;
  margin-bottom: 4px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
}

.file_uploading p svg {
  width: 16px;
  height: 16px;
  color: #2aa87e;
}
