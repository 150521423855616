.main {
  display: flex;
  flex-direction: row;
  background: var(--global-light-grey, #f3f8f8);
}
.content {
  height: 100%;
  min-height: 100vh;
  padding: 0 35px;
  width: 90%;
}

.content h1 {
  color: var(--Global-Grey, #243030);

  /* TierOneAI/H4 */
  font-family: Lexend;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%; /* 35px */
  letter-spacing: -0.087px;
}

.border {
  border: 1px solid var(--global-outline-grey, #dae8e7);
  border-radius: 8px;

  margin-top: 15px;
}

.all {
  display: flex;
  flex-direction: row;

  padding: 36px;
  gap: 25px;
}
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}
.header_buttons {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}
.return {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 8px;
  border: 1px solid var(--Global-Body-Grey, #5b706f);
  color: var(--Global-Body-Grey, #5b706f);
  text-align: center;

  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  height: 48px;
}

.assessment_section {
  border: dashed 1px var(--global-outline-grey, #8ca19f);
  width: 280px;
  height: 388px;
  font-family: Lexend;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
}
.essay_section {
  width: 78%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.description {
  width: 100%;
}

.essay_heading p {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0em;
  color: var(--Global-Grey, #5b706f);
  margin: 0;
}

.submit {
  width: 265px;
  height: 48px;
  padding: 8px, 24px, 8px, 24px;
  border-radius: 8px;
  gap: 8px;
}

.essay_buttons {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.upload {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid var(--Global-Body-Grey, #5b706f);
  color: #fff;
  text-align: center;
  cursor: pointer;
  background: #5b706f;
}

.upload:disabled {
  background: var(--TierOneAI-Disabled-Grey, #dae8e7);
  color: var(--TierOneAI-Disabled-White, #5b706f);
  border: none;
  cursor: not-allowed;
}

.submit {
  background: #2aa87e;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid var(--Global-Body-Grey, #5b706f);
  color: #fff;
  text-align: center;
  cursor: pointer;
}

.submit:disabled {
  background: var(--TierOneAI-Disabled-Grey, #dae8e7);
  color: var(--TierOneAI-Disabled-White, #5b706f);
  border: none;
  cursor: not-allowed;
}

.add_essay {
  width: 177px;
  height: 48px;
  padding: 8px 16px 8px 16px;
  border-radius: 8px;
  gap: 5px;
  background: #2aa87e;
  color: #fff;
  cursor: pointer;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.post_body_content {
  transition: all 0.3s ease-in-out;
}

.post_content {
  max-height: 155px;
  overflow: hidden;
  max-height: all 0.5s cubic-bezier(0, 1, 0, 1);
}

.post_content_expanded {
  max-height: 100%;
  overflow: hidden;
  transition: all 1s ease-in-out;
}

.buttonExpand {
  border: none;
  background: none;
  cursor: pointer;
  color: var(--global-form-grey, #8ca19f);

  /* TierOneAI/Element */
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 155%; /* 24.5px */
}
.button_section {
  display: flex;
  flex-direction: row;

  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

.button_section svg {
  width: 16px;
  height: 16px;
  color: var(--global-body-grey, #5b706f);
}
.nfti_assessment_top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}
.nfti_assessment {
  width: Fill (896px);
  height: Hug (798px);
  padding: 24px;
  border-radius: 8px;

  gap: 24px;
  border: 1px solid #dae8e7;
  margin-bottom: 24px;
}

.nfti_assessment h3 {
  font-family: Lexend;
  font-size: 25px;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: -0.0031em;
  text-align: left;
  color: #243030;
  margin-top: 10px;
  margin-bottom: 0;
}

.nfti_assessment h5 {
  font-family: Lexend;
  font-size: 10px;
  font-weight: 300;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
}

.nfti_assessment_content {
  display: flex;
  flex-direction: column;
}

.nfti_assessment_content h6 {
  color: #055370;
  font-family: Lexend;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 0px;
  margin-bottom: 20px;
}
.nfti_assessment_content h4 {
  margin: 15px 0;
  color: #243030;

  font-family: Lexend;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}
.college_list {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.college_list ul {
  list-style: none;
  padding: 0;
  margin: 0;
  color: #5b706f;
  font-family: Lexend;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.sub_container_right {
  display: flex;
  flex-direction: column;
  margin-left: 32px;
  background: #fff;
  padding: 36px;

  overflow-y: auto;
}

.sub_container_right h5 {
  margin: 0;
}

.sub_container_right h2 {
  color: var(--global-form-grey, #8ca19f);
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.sub_container_right p {
  color: var(--global-body-grey, #5b706f);

  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
  margin: 0;
  white-space: pre-wrap;
}

.sub_container_left {
  display: flex;

  flex-direction: column;
  border-radius: 8px;
  cursor: pointer;
  max-height: 67vh;
  overflow-y: auto;
  width: 20%;
}

.sub_container_left_top h5 {
  overflow: hidden;
  color: var(--global-grey, #243030);
  text-overflow: ellipsis;
  font-family: Lexend;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 175%;
  margin: 0;
}

.sub_container_left_bottom {
  background: black;
  color: white;
  padding: 15px;
}

.sub_container_left_bottom h5 {
  color: var(--global-white, #fff);
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Lexend;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 175%;
  margin: 0;
}

.sub_container_left_bottom p {
  color: var(--global-light-grey, #f3f8f8);
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.sub_container_left_top {
  background: var(--global-white, #fff);
  padding: 15px;
}
.sub_container_left_top p {
  color: var(--global-form-grey, #8ca19f);
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.sub_container_left_top h5 {
  overflow: hidden;
  color: var(--global-grey, #243030);
  text-overflow: ellipsis;
  font-family: Lexend;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 175%;
  margin: 0;
}

.sub_container_left_bottom {
  background: black;
  color: white;
  padding: 15px;
}

.sub_container_left_bottom h5 {
  color: var(--global-white, #fff);
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Lexend;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 175%;
  margin: 0;
}

.sub_container_left_bottom p {
  color: var(--global-light-grey, #f3f8f8);
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.return svg {
  width: 16px;
  height: 16px;
  color: var(--global-body-grey, #5b706f);
}

.upload svg {
  width: 16px;
  height: 16px;
}

.submit svg {
  width: 16px;
  height: 16px;
}

.add_essay svg {
  width: 16px;
  height: 16px;
}

.score h3 {
  color: #055370;
  font-family: Lexend;
  display: flex;
  flex-direction: row;

  gap: 6px;
}

.score svg {
  width: 24px;
  height: 24px;
  color: #00a99d;
  cursor: pointer;
}

.nfti_top_right {
  display: flex;
  flex-direction: column;

  align-items: flex-end;
}

.border {
  border: 1px solid var(--global-outline-grey, #dae8e7);

  margin-top: 15px;
  margin-bottom: 25px;
}

.score_detail h2 {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #055370;
}

.score_detail {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.score_detail_right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.score_detail_right h3 {
  font-family: Lexend;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #055370;
}

.score_body {
  margin-bottom: 24px;
}
.score_body p {
  font-family: Lexend;
  font-size: 14px;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}

.score_edit {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

.score_view {
  display: flex;
  flex-direction: column;
  text-align: right;
  align-items: center;
}
.add a {
  color: var(--NFTE-NFTE-Dark-Blue, #055370);
  text-align: center;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.add svg {
  width: 16px;
  height: 16px;
}
.add {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;

  cursor: pointer;
}

.curricular_section {
  display: flex;
  width: 100%;
  padding: 12px;
  flex-direction: column;

  gap: 24px;
  border-radius: 8px;
  border: 1px solid var(--TierOneAI-Outline-Grey, #dae8e7);
  background: var(--TierOneAI-Pale-Grey, #fafdfd);
}

.curricular_section {
  margin-top: 16px;
  margin-right: 10px;
  width: 98%;
}

.top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: right;
}

.delete {
  background: none;
  border: none;
  cursor: pointer;
  font-family: lexend;
  color: #5b706f;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;

  gap: 5px;
  width: 100%;
  justify-content: flex-end;
}

.delete svg {
  width: 16px;
  height: 16px;
}

.remove_modal {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
  padding: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background: var(--Global-White, #fff);

  border-radius: 8px;

  box-shadow: 0px 16px 64px 0px rgba(10, 18, 20, 0.25);
}

.remove_modal h2 {
  margin: 0;
}

.remove_modal p {
  color: var(--TierOneAI-Body-Grey, #5b706f);
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
  margin: 0;
}
.remove_modal_buttons {
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
}

.remove {
  display: flex;
  height: 48px;
  padding: 8px 36px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  background: var(--TierOneAI-Error-Red, #e03838);
  color: var(--Global-White, #fff);
  cursor: pointer;
  border: none;
}

.cancel {
  display: flex;
  height: 48px;
  padding: 8px 36px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid var(--Global-Grey, #243030);
  background: var(--TierOneAI-White, #fff);
  color: #0a1214;
  cursor: pointer;
}

.input_tab {
  width: 100%;
  gap: 24px;
  display: flex;
  flex-direction: column;
}

.justification_input {
  width: 100%;

  border-radius: 8px;

  font-family: Lexend;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--Global-Grey, #243030);
}

.score_field {
  width: 25px;
  height: 25px;

  padding: 0px, 8px, 0px, 8px;
  border-radius: 4px;
  border: 1px solid #dae8e7;
  gap: 8px;
  text-align: center;
  margin-right: 4px;
}

.aspect_field {
  width: 100%;

  font-family: Lexend;
  font-size: 14px;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}

.nfti_assessment_top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.header_section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.header_left {
  display: flex;
  flex-direction: column;
}

.header_left h6 {
  color: var(--Global-Grey, #5b706f);

  font-family: Lexend;
  font-size: 20px;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: -0.0031em;
  text-align: left;

  margin: 0;
}

.header_left p {
  font-family: Lexend;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 5px;
}

.header_right {
  width: 15%;
}

.save {
  font-family: Lexend;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #2aa87e;
  cursor: pointer;
  border: none;
  background: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
}

.save svg {
  width: 16px;
  height: 16px;
  color: #2aa87e;
}

.reject {
  font-family: Lexend;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #5b706f;
  cursor: pointer;
  border: none;
  background: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
}

.reject svg {
  width: 16px;
  height: 16px;
  color: #5b706f;
}

.score_view h5 {
  font-family: Lexend;
  font-size: 8px;
  font-weight: 300;
  line-height: 12px;
  letter-spacing: 0em;
}

.sub_container_left_top h4 {
  margin: 0;
  font-family: Lexend;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #8ca19f;
}

.sub_container_left_bottom h4 {
  margin: 0;
  font-family: Lexend;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.sub_container_left_top h5 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
}

.sub_container_left_bottom h5 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
}

.add p {
  font-family: Lexend;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #055370;
}

.edit_icon {
  color: #00a99d;
  cursor: pointer;
}

.page {
  display: none;
}
