.parent-container {
  margin: 100px auto 0;
  max-width: 800px; /* Adjust the maximum width as needed */
  padding: 0 20px;
}

.main-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.head-text {
  font-family: "Roobert";
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 58px;
  text-align: center;
  color: #231f20;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.new-post-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  width: 202px;
  height: 40px;
  background: #00bf8c;
  border-radius: 2px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.new-post-text {
  font-family: "Roobert";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 144%;
  text-align: center;
  hanging-punctuation: first last;
  color: #231f20;
}

.body-text {
  display: flex;
  flex-direction: column;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.chat-box {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 808px;
  min-height: 1000px;
  background: #ffffff;
  border: 1px solid #617388;
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
  padding-bottom: 20px;
}

.parent-chat-box {
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 50px;
}

.row-chat-close {
  display: flex;
  flex-direction: column;
  padding: 36px;
  gap: 24px;
  width: 708px;
  min-height: 60px;
  background: #f8f8f8;
  border-radius: 8px;
  flex: none;
  order: 4;
  flex-grow: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-top: 20px;
}

.row-chat-open {
  display: flex;
  flex-direction: column;
  padding: 36px;
  gap: 24px;
  width: 708px;
  min-height: 60px;
  background: #f8f8f8;
  border-radius: 8px;
  flex: none;
  order: 4;
  flex-grow: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-top: 20px;
}

.chat-date {
  font-family: "Roobert";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 144%;
  color: rgba(102, 112, 133, 0.75);
  flex: none;
  order: 0;
  flex-grow: 0;
}

.chat-date-2 {
  font-family: "Roobert";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 144%;
  color: rgba(102, 112, 133, 0.75);
  flex: auto;
  order: 0;
  flex-grow: 0;
}

.chat-title-div {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.chat-title {
  font-family: "Roobert";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #231f20;
  flex: none;
  order: 0;
  flex-grow: 1;
}

.my-answer-text {
  font-family: "Roobert";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 144%;
  color: #617388;
}

.coach-name-text {
  font-family: "Roobert";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 144%;
  color: #617388;
}

.horozontal-line {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 736px;
  border-bottom: 4px solid #617388;
}

.chat-text {
  font-family: "Roobert";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 144%;
  color: #4f4c4d;
}

.divider-line {
  border: 1px solid rgba(102, 112, 133, 0.25);
  flex: none;
  align-self: stretch;
  flex-grow: 0;
}

.chat-meta-div-parent {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.chat-meta-div {
}

.pm-coach-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 12px;
  gap: 8px;
  width: 87px;
  height: 20px;
  background: #00bf8c;
  border-radius: 48px;
}

.client-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 12px;
  gap: 8px;
  width: 87px;
  height: 20px;
  background: #f1f1f1;
  border-radius: 48px;
}

.pm-coach-text {
  font-family: "Roobert";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  text-align: center;
  color: #231f20;
}

.reply-box-parent {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 16px;
  width: 736px;
  min-height: 296px;
  border: 1px solid #617388;
  border-radius: 8px;
  flex: none;
  order: 7;
  align-self: stretch;
  flex-grow: 0;
}

.reply-textarea {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 16px;
  gap: 8px;
  width: 688px;
  min-height: 144px;
  border: 1px solid #617388;
  border-radius: 4px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  font-family: "Roobert";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
}

.post-reply-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  width: 128px;
  height: 40px;
  background: #617388;
  border-radius: 2px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-right: 10px;
}

.post-reply-text {
  font-family: "Roobert";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 144%;
  text-align: center;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.confirm-close-button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  width: 293px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #617388;
  border-radius: 2px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.confirm-close-text {
  font-family: "Roobert";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 144%;
  text-align: center;
  color: #617388;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.light-blue {
  background-color: lightblue;
}
