.main {
  width: 100%;
  background: var(--global-light-grey, #f3f8f8);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  padding: 0 35px;
  width: 95%;
  background-color: var(--global-light-grey, #f3f8f8);
  max-width: 1600px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content h1 {
  color: var(--Global-Grey, #243030);
  font-family: Lexend;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: -0.087px;
}
.container {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  width: 100%;
  background-color: #fff;
  flex-direction: column;
  align-items: start;
  height: 100%;
  padding: 20px;
  gap: 40px;
}
.border {
  border: 1px solid var(--global-outline-grey, #dae8e7);

  margin-top: 10px;
  margin-bottom: 30px;
}
.navbar {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  height: 60px;
  justify-content: space-between;
}
.nav_right {
  display: flex;
  align-items: center;
  gap: 10px;
}
.nav_btn1 {
  border: 1px solid #bbbfbf;
  padding: 0 15px;
  border-radius: 5px;
  width: fit-content;
  display: flex;
  gap: 8px;
  align-items: center;
}

.nav_btn2 {
  border: 1px solid #00706c;
  padding: 0 15px;
  border-radius: 5px;
  width: fit-content;
  display: flex;
  gap: 8px;
  align-items: center;
  background: none;
  outline: none;
  height: 43px;
  color: var(--Global-Grey, #00706c);
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  text-wrap: nowrap;
  text-decoration: none;
}

.nav_btn2:hover {
  background-color: #2aa87e;
  color: #fff;
}
.navbar_container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
@media (max-width: 425px) {
  .content {
    padding: 0 16px;
  }
  .learner_header h2 {
    font-size: 17px;
  }
  .learner_header {
    flex-direction: column;
    gap: 16px;
  }
  .search {
    width: 90%;
  }
  .learner_header_buttons {
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }

  .coach_roaster {
    padding: 20px;
  }

  .learner_table thead th {
    font-size: 10px;
  }

  .modal_view {
    width: 80%;
  }
}

/* more css start from there */
.container_split {
  display: flex;
  gap: 5px;
  align-items: stretch; /* Ensure both containers stretch to the same height */
  width: 100%;
  height: 100%;
}

.container_left {
  flex: 1; /* Take up equal space, or adjust for different ratios */
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* 'start' doesn't exist, 'flex-start' is the correct value */
  padding: 0 30px;
  gap: 20px;
  position: relative;
  max-width: 650px;
}

.container_right {
  flex: 1;
  position: relative;
  border: 1px solid #dae8e7;
  border-radius: 8px;
  padding: 0 30px;
  min-height: 100%;
  max-width: 550px;
}
.left_nav {
  display: flex;
  width: 100%;
}

.left_nav_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.nav_item {
  display: flex;
  gap: 5px;
  align-items: center;
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  color: #243030;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  width: 100%;
  text-wrap: nowrap;
}
.nav_item:hover {
  color: #2aa87e;
}

.line {
  width: 99.97%;
  border: 1px solid var(--global-outline-grey, #00706c);
}

.persona_container {
  height: 95%;
  width: 97%;
  padding: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}
.persona_container h1 {
  color: #243030;
  text-wrap: nowrap;
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
}
.persona_information {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  width: 70%;
}
.persona_image {
  width: 128px;
  height: 128px;
  overflow: hidden;
  border-radius: 50%;
}
.persona_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.info_one {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.info_one h3 {
  color: #00706c;
  text-wrap: nowrap;
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 0;
}

.info_one p {
  color: #5b706f;
  text-wrap: nowrap;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 0;
  margin-top: 5px;
}
.info_one span {
  margin-top: 5px;
}
.info_two {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin-top: 20px;
}
.info_two h3 {
  color: #243030;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 0;
}
.info_two p {
  color: #5b706f;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  text-align: center;
  margin-top: -5px;
}
.start_call {
  width: 100%;
  padding: 15px 6px;
  display: flex;
  align-items: center;
  background-color: #00706c;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  color: #fff;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  justify-content: center;
  gap: 10px;
  text-decoration: none;
}
.start_call:hover {
  background-color: #04413e;
}
.buyer_persona {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.call_leaderboard {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: start;
  width: 100%;
  height: 100%;
}
.call_leaderboard_timeline {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-evenly;
  width: 100%;
}
.timeline_btn {
  width: 100%;
  background-color: #f3f8f8;
  border: 1px solid #dae8e7;
  outline: none;
  padding: 6px 10px;
  border-radius: 5px;
  color: #475555;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
}
.widget_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 150px;
  gap: calc(100% * 0.2) calc(150px * 0.6);
  width: 90%;
  margin-bottom: 60px;
}

.call_record_chart {
  border: 1px solid #dae8e7;
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 20px;
  border-radius: 8px;
  padding: 20px;
  height: 100%;
}
.call_record_chart_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.call_record_chart_header a {
  text-decoration: none;
  color: #40806a;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.line2 {
  width: 99.97%;
  border: 1px solid var(--global-outline-grey, #dae8e7);
}
.status {
  padding: 3px 20px;
  background-color: white;
  border-radius: 20px;
  text-wrap: nowrap;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  margin-left: 5px;
  margin-right: 5px;
}

.skeleton_container {
  height: 100%;
  width: 97%;
  padding: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  max-height: 80vh;
}

/* Skeleton Loader Styles */
.skeleton_title,
.skeleton_text,
.skeleton_brief_title,
.skeleton_brief_description,
.skeleton_status {
  background: #e0e0e0;
  border-radius: 4px;
  animation: pulse 1.5s infinite ease-in-out;
}

.skeleton_circle {
  width: 128px;
  height: 128px;
  border-radius: 50%;
  background: #e0e0e0;
  animation: pulse 1.5s infinite ease-in-out;
}

.skeleton_title {
  width: 100%;
  height: 30px;
}

.skeleton_text {
  width: 60%;
  height: 20px;
}

.skeleton_brief_title {
  width: 50%;
  height: 20px;
}

.skeleton_brief_description {
  width: 90%;
  height: 20px;
}

.skeleton_status {
  width: 80%;
  height: 20px;
}

@keyframes pulse {
  0% {
    background-color: #e0e0e0;
  }
  50% {
    background-color: #f0f0f0;
  }
  100% {
    background-color: #e0e0e0;
  }
}

.skeleton_information {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  width: 60%;
}

.skeleton_image {
  width: 128px;
  height: 128px;
  overflow: hidden;
  position: relative;
}

.skeleton_info_one {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skeleton_info_two {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin-top: 20px;
}

.skeleton_start_call {
  width: 100%;
  padding: 15px 6px;
  display: flex;
  align-items: center;
  background-color: #c2c0c0;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  color: #fff;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  justify-content: center;
  gap: 10px;
  text-decoration: none;
  opacity: 0.5;
}
.behavior {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}
