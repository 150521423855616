.app-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.header-text {
    display: flex;
    font-family: 'Roobert';
    font-style: normal;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.sub-text {
    font-family: 'Roobert';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 144%;
    text-align: center;
    letter-spacing: -0.0125em;
    color: #4F4C4D;
}

.email-input {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 12px 16px;
    gap: 8px;
    width: 512px;
    height: 53px;
    background: #FFFFFF;
    border: 1px solid #617388;
    border-radius: 4px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    font-size: 20px;
}

.password-field {
    display: flex;
    align-items: center;
}

.password-input {
    padding: 12px 16px;
    width: 512px;
    height: 53px;
    background: #FFFFFF;
    border: 1px solid #617388;
    border-radius: 4px;
    font-size: 18px;
    margin-top: 15px;
}

.toggle-password {
    background: none;
    border: none;
    color: #617388;
    cursor: pointer;
    margin-left: 10px;
    font-size: 14px;
  }

.toggle-password-visibility {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    margin-left: 8px;
}
.button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 36px;
    gap: 12px;
    width: 156px;
    height: 64px;
    background: #00BF8C;
    border-radius: 2px;
    flex: none;
    order: 0;
    flex-grow: 0;
    cursor: pointer;
    margin-top: 15px;
  }
  .button:hover {
    cursor: pointer;
  }

  .button-text {
    font-family: 'Roobert';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 144%;
    text-align: center;
    hanging-punctuation: first last;
    color: #231F20;
  }