.app-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
}

.content-container {
  display: flex;
  flex-grow: 1;
}

.main-content.with-sidebar {
  margin-left: 220px;
  width: calc(100% - 260px);
}

.main-content.no-sidebar {
  margin-left: 0;
  width: 100%;
}

.main-content {
  padding: 16px;
  transition: margin-left 0.3s;
}

.sidebarDrawer.sidebarDrawer-collapsed + .main-content {
  margin-left: 70px;
  width: calc(100% - 100px);
}
