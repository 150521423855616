.query {
  display: flex;
  flex-direction: row;
  background: var(--global-light-grey, #f3f8f8);
  height: 100%;
  min-height: 100vh;
}

.query__container {
  display: flex;
  width: 90%;
  flex-direction: column;
  padding: 32px;
  height: 100%;
}

.query__container__header h4 {
  color: var(--global-grey, #243030);
  font-family: Lexend;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
  letter-spacing: -0.087px;
  margin: 0;
}
.query__container__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.query_header__right {
  display: flex;
  flex-direction: row;
  gap: 15px;
}
.add_query {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 8px;
  background: var(--nftenfte-purple, #4c12a1);
  color: var(--global-white, #ffffff);
  font-family: Lexend;
  font-size: 16px;
  width: 144px;
  height: 48px;
  border: none;
  cursor: pointer;
}

.add_query svg {
  width: 16px;
  height: 16px;
}
.buttons label {
  color: var(--global-body-grey, #5b706f);
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
}
.border {
  margin-top: 16px;
  border-bottom: 1px solid var(--global-outline-grey, #dae8e7);
}

.query__container__body {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
}

.query__container__body h4 {
  color: var(--global-grey, #243030);
  font-family: Lexend;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
  letter-spacing: -0.087px;
  margin: 0;
}

.query__container__body p {
  color: var(--global-body-grey, #5b706f);
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
}

.sub_container {
  display: flex;
  flex-direction: row;

  margin-top: 32px;
}

.sub_container_left {
  display: flex;
  width: 350px;
  flex-direction: column;
  border-radius: 8px;
}

.sub_container_left_top {
  background: var(--global-white, #fff);
  padding: 15px;
}

.sub_container_left_top p {
  color: var(--global-form-grey, #8ca19f);
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin: 0;
}

.sub_container_left_top p:last-child {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sub_container_left_top h5 {
  overflow: hidden;
  color: var(--global-grey, #243030);
  text-overflow: ellipsis;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 175%;
  margin: 0;
}

.sub_container_left_bottom {
  background: black;
  color: white;
  padding: 15px;
}

.sub_container_left_bottom h5 {
  color: var(--global-white, #fff);
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Lexend;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 175%;
  margin: 0;
}

.sub_container_left_bottom p {
  color: var(--global-light-grey, #f3f8f8);
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin: 0;
}

.sub_container_left_bottom p:last-child {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sub_container_right_section {
  width: 100%;
}

.sub_container_right {
  display: flex;
  flex-direction: column;
  margin-left: 32px;
  background: #fff;
  padding: 36px;
}

.sub_container_right h5 {
  margin: 0;
}

.sub_container_right h2 {
  color: var(--global-form-grey, #8ca19f);
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.sub_container_right p {
  color: var(--global-body-grey, #5b706f);

  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
  white-space: pre-wrap;
}

.sub_container_right h6 {
  color: var(--global-grey, #243030);
  margin: 0;
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
  letter-spacing: -0.062px;
}

.reply_section {
  border-radius: 4px;
  border: 1px solid var(--global-outline-grey, #dae8e7);
  background: var(--global-white, #fff);
  padding: 12px 16px;
  color: var(--global-form-grey, #8ca19f);
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
  min-height: 150px;
}

.bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.bottom_section_button {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 32px;
  gap: 10px;
}

.reply {
  border-radius: 8px;
  background: var(--global-outline-grey, #dae8e7);
  background-blend-mode: multiply;
  display: flex;
  height: 48px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: none;
  color: var(--global-form-grey, #8ca19f);
  text-align: center;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.reply svg {
  width: 16px;
  height: 16px;
}

.close {
  display: flex;
  height: 48px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--nftenfte-purple, #4c12a1);
  background: #ffffff;
  color: var(--nftenfte-purple, #4c12a1);
  text-align: center;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.disabled {
  display: flex;
  height: 48px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--global-outline-grey, #dae8e7);
  background-blend-mode: multiply;

  color: var(--global-form-grey, #8ca19f);
  text-align: center;
  border: none;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}
.report {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  color: var(--global-grey, #243030);
  text-align: right;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
  cursor: pointer;
}

.report svg {
  width: 16px;
  height: 16px;
}

.person {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.personHeader {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.personNameText {
  font-size: 16px;
  font-weight: 500;
  color: var(--global-grey, #243030);
}

.person img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.confirm_msg {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  color: var(--global-body-grey, #5b706f);
  font-family: Lexend;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 175%;
}

.confirm_msg svg {
  width: 16px;
  height: 16px;
}

.reopen {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  color: var(--global-body-grey, #5b706f);
  font-family: Lexend;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 175%;
  cursor: pointer;
}

.reopen svg {
  width: 16px;
  height: 16px;
}

.empty_text {
  color: var(--global-grey, #243030);
  font-family: Lexend;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 175%;
}

.query_link {
  color: var(--nftenfte-purple, #4c12a1);
  font-family: Lexend;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
  letter-spacing: -0.075px;
  cursor: pointer;
}
